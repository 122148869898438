import { ACTIONS } from './actions';

const initialState = {
  seasonId: '',
  list: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.read.success: {
      return {
        ...state,
        seasonId: action.seasonId,
        list: action.list
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
