import newId from '../../utils/guids';
import {sha512} from '../../utils/crypt';
import {initUserDB, initRemoteDB, initSyncDB} from '../service/actions';
import {DOC_TYPES} from '../../utils/pouchDB/documents';
import {getDB, writeDoc, readDoc} from '../../utils/pouchDB/general';
import {DB_TYPES} from "../../constants/pouchDB";
import {ID_SIZE_USERS} from "../../constants/ids";
import Api from "../../utils/api/Api";
import {beHost} from "../../constants/general";

export const ACTION_TYPES = {
  clear: 'USERS_CLEAR_STATE',
  auth: {
    success: 'USERS_AUTH_SUCCESS',
    // error: 'USERS_AUTH_ERROR',
    // logout: 'USERS_AUTH_LOGOUT',
  },
  local: {
    success: 'USER_LOCAL_SUCCESS'
  },
  current: {
    success: 'USER_CURRENT_SUCCESS'
  },
  // login: {
  //   login: 'USERS_LOGIN_LOGIN',
  //   success: 'USERS_LOGIN_SUCCESS',
  //   error: 'USERS_LOGIN_ERROR'
  // }
};

export const ACTIONS = {
  clear: () => ({type: ACTION_TYPES.clear}),
  auth: {
    success: web => ({type: ACTION_TYPES.auth.success, web}),
  },
  local: {
    success: local => ({type: ACTION_TYPES.local.success, local})
  },
  current: {
    success: current => ({type: ACTION_TYPES.current.success, current})
  },
  // login: {
  //   login: 'USERS_LOGIN_LOGIN',
  //   success: 'USERS_LOGIN_SUCCESS',
  //   error: 'USERS_LOGIN_ERROR'
  // }
};


// const dbLoginSuccess = user => ({type: ACTIONS.login.success, user});

const errorMessage = msg => {
  let message = msg;

  if (typeof msg !== 'string') {
    try {
      message = JSON.stringify(msg);
    } catch (e) {
      try {
        message = msg.toString();
      } catch (e) {
        message = `can not parse message ${msg}`;
      }
    }
  }
  return {message};
};

const webToken = async user => {
  let result = null;

  try {
    const response = await apiTokenAuth(user);
    // console.info('response', response);
    if (response) {
      if (response.token && response['refresh-token']) {
        result = response;
      } else if (response.message) {
        console.error('Error while get token. Message:', response.message);
        result = errorMessage(response.message);
      } else {
        console.error('Error while get token. Response:', response);
        result = errorMessage(response);
      }
    } else {
      console.error('Error while get token. Empty response');
      result = errorMessage('Response message is empty');
    }
  } catch (e) {
    console.error('Error while get token. Error:', e.message);
    result = errorMessage(e.message);
  }

  return result;
};
const webUserId = async token => {
  let result = null;

  try {
    const response = await apiGetUserId(token);
    // console.info('response', response);
    if (response) {
      if (response.id) {
        result = response;
      } else if (response.message) {
        console.error('Error while get user id. Message:', response.message);
        result = errorMessage(response.message);
      } else {
        console.error('Error while get user id. Response:', response);
        result = errorMessage(response);
      }
    } else {
      console.error('Error while get user id. Empty response');
      result = errorMessage('Response message is empty');
    }
  } catch (e) {
    console.error('Error while get user id. Error:', e.message);
    result = errorMessage(e.message);
  }

  return result;
};
const webLogin = async user => {
  const {login, password} = user;

  let result = null;
  const authResponse = await webToken(user);
  if (authResponse && authResponse.token && authResponse['refresh-token']) {
    // console.info('authResponse', authResponse);
    const userIdResponse = await webUserId(authResponse.token);
    if (userIdResponse && userIdResponse.id) {
      result = {...authResponse, ...userIdResponse, login};
    } else {
      result = userIdResponse;
    }
  } else {
    result = authResponse;
  }
  return result;
};
const webRegister = async user => {
  let result = null;
  try {
    const response = await apiRegister(user);
    if (response) {
      if (response.message === 'User was created') {
        result = '';
      } else if (response.message) {
        result = response.message;
        console.error('Error while get token. Message:', result);
      } else {
        console.error('Error while register new user. Response:', response);
        result = response;
      }
    } else {
      console.error('Error while register new user. Empty response');
      result = 'Response message is empty';
    }
  } catch (err) {
    console.error('Error while register new user.', err);
    result = err.message;
  }
  return result;
};

const initUser = () => async (dispatch, getState) => {
  const {user: {web: {id, login}}} = getState();
  const foundDoc = await dispatch(readDoc(id, DB_TYPES.user));
  if (!foundDoc) {
    console.error('Error while reading user from user DB. Response:', foundDoc);
  } else {
    if (foundDoc._id !== id || foundDoc.login !== login) {
      return await dispatch(saveUser({...foundDoc, _id: id, login}));
    } else {
      return foundDoc;
    }
  }

  return null;
};

export const onLogin = user => async dispatch => {
  dispatch(ACTIONS.clear());

  const {password} = user;

  // console.info('onLogin', user);

  const webLoginResponse = await webLogin(user);
  if (!(webLoginResponse && webLoginResponse.token && webLoginResponse['refresh-token'] && webLoginResponse.id)) {
    return webLoginResponse;
  }
  //     if (!webLoginResponse) {
  //   return false;
  // }
  dispatch(ACTIONS.auth.success(webLoginResponse));

  const passwordHash = await sha512(password);
  const localUser = await dispatch(saveLocalUser({passwordHash}));

  if (localUser) {
    dispatch(ACTIONS.local.success(localUser));

    const [userDB, remoteDB] = await Promise.all([
      dispatch(initUserDB()),
      dispatch(initRemoteDB())
    ]);

    if (userDB) {
      const currentUser = await dispatch(initUser());
      if (currentUser) {
        dispatch(ACTIONS.current.success(currentUser));

        if (remoteDB) {
          dispatch(ACTIONS.current.success(currentUser));
          await dispatch(initSyncDB());
        }

        return errorMessage('');
      }
    }
  }

  dispatch(ACTIONS.login.error());
  return errorMessage('unknown error');
};

export const onRegister = user => async dispatch => {
  // console.info('onRegister', user);
  return await webRegister(user);
};

const apiRegister = async ({name, login, password, email}) => {
  return await Api.post(`${beHost}/register`, {name, login, password, email})
    // .then(response => {
    //   console.info('response', response);
    // })
    ;
};
const apiTokenAuth = async ({login, password}) => {
  return await Api.post(`${beHost}/token-auth`, {login, password})
    // .then(response => {
    //   console.info('response', response);
    // })
    ;
};
const apiGetUserId = (token) => {
  Api.token = token;
  // return Api.get(`${beHost}/testauth`)
    return Api.get(`${beHost}/get-user-id`)
    // .then(response => {
    //   console.info('apiGetUserId', response);
    // })
    ;
};


export const readUsers = () => dispatch => {
  const db = dispatch(getDB(DB_TYPES.service));
  if (db) {
    return db
      .allDocs({include_docs: true})
      .then(result => {
        console.log('Local users:', {localUsers: result.rows});
        return result.rows
          .filter(row => row.doc.type === DOC_TYPES.user)
          .map(row => row.doc);
      })
      .catch(err => {
        throw err;
      });
  }
};

export const saveUser = (userData = {}) => async (dispatch, getState) => {
  const {user: {current}} = getState();
  const doc = {
    ...current,
    ...userData,
    type: DOC_TYPES.user
  };

  try {
    const writeResult = await dispatch(writeDoc(doc, DB_TYPES.user));
    if (writeResult === true) {
      if (doc.created) {
        return doc;
      } else {
        return await dispatch(readDoc(doc._id, DB_TYPES.user));
      }
    }
  } catch (err) {
    console.error('Error while saving user in user DB', err);
  }

  return null;
};
export const saveLocalUser = userData => async (dispatch, getState) => {
  const {user: {web: {id, login}, local}} = getState();
  const doc = {
    ...local,
    ...userData,
    type: DOC_TYPES.user,
    _id: id,
    login,
  };

  try {
    const writeResult = await dispatch(writeDoc(doc, DB_TYPES.service));
    if (writeResult === true) {
      return doc;
    }
  } catch (err) {
    console.error('saveLocalUser', err);
  }

  return null;
};

const getNewUserId = () => newId(ID_SIZE_USERS);
