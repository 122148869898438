import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import styles from './Input.module.css';
import ValidationIcon from './ValidationIcon';
import Textarea from './Textarea';
import RadioButtons from './RadioButtons';
import FileUploader from './FileUploader';

const Input = (props) => {
    const {
        rootClassName,
        label,
        className,
        type,
        required, // just remove this value from rest.
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...rest
    } = props;

    let input = null;

    if (!rest.id) {
        rest.id = `input_${field.name}`;
    }

    const fieldError = get(errors, field.name);
    const fieldTouched = get(touched, field.name) || props.form.submitCount > 0;

    const inputClassName = cn(
        (type !== 'radio_buttons') && styles.input,
        rest.disabled && styles.inputDisabled,
        fieldTouched && fieldError && styles.inputInvalid,
        className
    );

    switch (type) {
    case 'select':
        input = (
            <select
                {...field}
                {...rest}
                value={field.value || ''}
                className={inputClassName}
            />
        );
        break;
    case 'textarea':
        input = (
            <Textarea
                {...field}
                {...rest}
                value={field.value || ''}
                className={inputClassName}
            />
        );
        break;
    case 'radio_buttons':
        input = (
            <RadioButtons
                {...field}
                {...rest}
                value={field.value || ''}
                className={inputClassName}
            />
        );
        break;
    // case 'file_uploader':
    //     input = (
    //         <FileUploader
    //             {...field}
    //             {...rest}
    //             form={props.form}
    //             value={field.value || ''}
    //             className={inputClassName}
    //         />
    //     );
    //     break;
    default:
        input = (
            <input
                type={type ? type : "text"}
                {...field}
                {...rest}
                value={field.value || ''}
                className={inputClassName}
            />
        );
    }

    return (
        <div className={cn(styles.inputRoot, rootClassName)}>
            {!!label && <label htmlFor={rest.id} className={styles.label}>{label}</label>}
            <div className={styles.inputWrapper}>
                <ValidationIcon {...props} {...rest} {...{ fieldError, fieldTouched }} />
                {input}
                {fieldTouched && fieldError && (
                    <div className={cn(styles.error, styles.errorBelow)}>
                        {fieldError}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Input;
