import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
// import cn from 'classnames';
import * as seasonsActions from "../../modules/seasons/actions";
import styles from './Season.module.css';
import {Link, Route} from 'react-router-dom';
import {ROUTE} from "../../constants/general";
import Button from "../../components/Button/Button";
import EpisodeListDetailRoute from "../episodes/EpisodeListDetailRoute";
import EpisodeList from "../episodes/EpisodeList";

const initialState = {
  season: {},
  showEpisodes: false
};

class Season extends Component {
  state = initialState;

  componentDidMount() {
    this.updateData();
  }
  componentWillUpdate(nextProps, nextState, nextContext) {
    console.log('componentWillUpdate', nextProps, nextState, nextContext)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('componentDidUpdate', prevProps, prevState, snapshot)
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    console.log('shouldComponentUpdate', nextProps, nextState, nextContext)
    return true;
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const {seasonId} = this.props;
  //   console.log('prevProps.seasonId !== seasonId', prevProps.seasonId !== seasonId);
  //   if (prevProps.seasonId !== seasonId) {
  //     this.updateData();
  //   }
  // }

  updateData = () => {
    const {seasonId, fetch} = this.props;
    fetch(seasonId).then(doc => {
      this.setState({season: doc})
    });
  };


  composeFiled = (name, value) => {
    return (
      <div className={styles.fieldsRow}>
        <div className={styles.fieldName}>
          {name}:
        </div>
        <div className={styles.fieldValue}>
          {value}
        </div>
      </div>
    );
  };

  render() {
    const {activeSeasonId, seasonIdFilter, seriesId, seasonId, history} = this.props;
    let {season, showEpisodes} = this.state;

    console.info('season ' + seasonId + ' this.props', this.props);
    if (season === undefined) {
      console.error('season undef. why?', season, seasonId);
      season = {};
    }

    // console.error('seriesId, seasonId, link, this', seriesId, seasonId, ROUTE.series.seasons.episodes.root(seriesId, seasonId), this.props);
    const actions = [];
    if (activeSeasonId === seasonId && showEpisodes) {
      actions.push(
        <Button key="hideEpisodes" onClick={() => {
          this.setState({showEpisodes: false});
          history.push(ROUTE.series.id(season.seriesId));
        }}>
          hide Episodes
        </Button>
      );
    } else {
      actions.push(
        <Button key="showEpisodes" onClick={() => {
          this.setState({showEpisodes: true});
          history.push(ROUTE.series.seasons.episodes.root(season.seriesId, seasonId));
        }}>
          show Episodes
        </Button>
      );
    }

    return (
      <Fragment>
        <h1>{season.title}</h1>

        <div className={styles.seasonData}>
          <img className={styles.image} src="// TODO" alt={season.title}/>
          <div className={styles.fieldsBlock}>
            {this.composeFiled('Season ID', season._id)}
            {this.composeFiled('Created', new Date(season.created).toLocaleDateString())}
            {this.composeFiled('Description', season.description)}
            {this.composeFiled('Keywords', season.keywords)}
            {this.composeFiled('Playlist level', '2 //TODO: is it always 2?')}
          </div>
        </div>
        {actions}

        <Route path={ROUTE.series.seasons.episodes.root(seriesId, seasonId)} component={EpisodeListDetailRoute}/>
        {/*<Route path={ROUTE.series.seasons.episodes.root(seriesId, seasonId)}>*/}
          {/*<Route path={ROUTE.series.seasons.episodes.root()} component={EpisodeList}/>*/}
        {/*</Route>*/}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // seriesId: ownProps.match.params.seriesId,
  // seriesId: state.match.params.seriesId,
  // activeSeasonId: ownProps.match.params.seasonId
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(seasonsActions.fetch(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Season);
