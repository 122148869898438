import {ACTION_TYPES, ACTIONS} from './actions';
import {defaultProjectPath} from "../../constants/general";

const initialState = {
  web: {
    id: '',
    token: '',
    'refresh-token': ''
  },
  local: {
    _id: '',
    login: '',
    projectPath: defaultProjectPath,
    created: '',
    updated: ''
  },
  current: {
    _id: '',
    login: '',
    settings: {},
    created: '',
    updated: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.clear: {
      return initialState;
    }
    case ACTION_TYPES.auth.success: {
      return {
        ...state,
        web: {...state.web, ...action.web}
      };
    }
    case ACTION_TYPES.local.success: {
      return {
        ...state,
        local: {...state.local, ...action.local}
      };
    }
    case ACTION_TYPES.current.success: {
      return {
        ...state,
        current: {...state.current, ...action.current}
      };
    }
    // case ACTIONS.login.success: {
    //   const {_id, login, projectPath = initialState.projectPath} = action.current;
    //   return {
    //     ...state,
    //     current: {...state.current, _id, login, projectPath}
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default reducer;
