import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {ROUTE} from "../../constants/general";
import {ModalRoute} from "react-router-modal";
import Button from "../../components/Button/Button";
import * as episodesActions from "../../modules/episodes/actions";
import styles from "./EpisodeList.module.css";
import EpisodeCreate from "./EpisodeCreate";
import Episode from "./Episode";
import Page from "../../components/Page/Page";

class EpisodeList extends Component {

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {seasonId} = this.props;
    if (prevProps.seasonId !== seasonId) {
      this.update();
    }
  }

  update = () => {
    const {seasonId, fetchEpisodes} = this.props;
    fetchEpisodes(seasonId);
  };

  convertToContainerData = doc => {
    const {remove, fetchNextPage} = this.props;
    return {
      id: doc._id,
      url: ROUTE.series.seasons.episodes.id(doc.seriesId, doc.seasonId, doc._id),
      idLabel: 'PLID',
      details: {
        title: doc.title,
        fields: [
          {key: 'Created', value: doc.created},
          {key: 'Number of Videos', value: doc.numberOfVideos},
          {key: 'Playlist level', value: 3},
        ],
      },
      actions: [
        <Button key="episodeDelete" onClick={() => {
          remove(doc._id).then(() => fetchNextPage());
        }}>
          del
        </Button>
      ]
    };
  };

  handleEpisodeCreate = () => {
    const {seriesId, seasonId, history} = this.props;
    history.push(ROUTE.series.seasons.episodes.create(seriesId, seasonId))
  };

  render() {
    const {seriesId, seasonId, episodes, history, activeEpisodeId} = this.props;

    const actions = [
      <Button key="episodeCreate" onClick={this.handleEpisodeCreate}>+ episode</Button>
    ];

    console.log('episodes', episodes);

    // const items = [];
    const items = episodes.list.map(doc => (
      <li key={doc._id} className={styles.episode}>
        <Episode activeEpisodeId={activeEpisodeId} episodeId={doc._id} history={history}/>
      </li>
    ));


    return (
      <Page title={`Episodes (${items.length})`}>
        {/*<h2>Episodes ({items.length})</h2>*/}
        {actions}
        <ul className={styles.episodeList}>
          {items}
        </ul>
        <ModalRoute path={ROUTE.series.seasons.episodes.create()} component={EpisodeCreate} />
        {/*<ListContainer header="Episodes" items={items} actions={actions}/>*/}
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  seriesId: ownProps.match.params.seriesId,
  seasonId: ownProps.match.params.seasonId,
  episodes: state.episodes,
  userDB: state.service.userDB
});

const mapDispatchToProps = dispatch => ({
  fetchEpisodes: seasonId => dispatch(episodesActions.fetchEpisodes(seasonId)),
  remove: id => dispatch(episodesActions.remove(id))
});
//   return bindActionCreators(projectActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeList);
