import {ACTION_TYPES, ACTIONS} from './actions';

const initialState = {
  list: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.projects.success: {
      return {
        ...state,
        list: action.list
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
