import { combineReducers } from 'redux'
import series from './series/reducer';
import seasons from './seasons/reducer';
import episodes from './episodes/reducer';
import links from './links/reducer';
import projects from './projects/reducer';
import service from './service/reducer';
import user from './user/reducer';

export default combineReducers({
  series,
  seasons,
  episodes,
  links,
  projects,
  service,
  user,
})
