import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Header.module.css';
import {ROUTE} from "../../constants/general";
import {Link} from 'react-router-dom';

class Header extends React.Component {
  render() {
    return (
      <header id="globalHeader">
        <img className={cn(styles.image)} src="// TODO" alt="logo"/>
        <div className={styles.searchBar}>
          TODO: search bar
        </div>
        <div className={styles.user}>
          <Link to={ROUTE.account.root}>
            {this.props.name}
          </Link>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  name: PropTypes.string,
};

const mapStateToProps = ({user}) => ({
  name: user.name,
});

export default connect(mapStateToProps)(Header);
