import newId from "../../utils/guids";
import {ID_SIZE_SERIES} from "../../constants/ids";
import {DOC_TYPES, getNewDocument} from "../../utils/pouchDB/documents";

export const ACTIONS = {
  list: {
    read: {
      success: 'SERIES_LIST_READ_SUCCESS'
    }
  },
  current: {
    read: {
      success: 'SERIES_CURRENT_READ_SUCCESS'
    }
  }
};

const OPERATIONS = {
  list: {
    read: {
      success: list => ({type: ACTIONS.list.read.success, list})
    }
  },
  current: {
    read: {
      success: current => ({type: ACTIONS.current.read.success, current})
    }
  }
};


export const getNewId = () => (dispatch, getState) => {
  const {user: {current: {_id: userId}}} = getState();
  return newId(ID_SIZE_SERIES, `${userId}sr`);
};

export const insert = values => (dispatch, getState) => {
  const doc = getNewDocument(DOC_TYPES.series, {...values, _id: dispatch(getNewId())});
  return dispatch(put(doc));
};

export const fetchNextPage = () => (dispatch, getState) => {
  const {service: {userDB}, series} = getState();

  const findOptions = {
    selector: {
      $and: [{type: {$eq: DOC_TYPES.series}}, {title: {$gt: null}}]
    },
    sort: [{title: 'asc'}],
    limit: 100
  };

  // console.log('series fetch: 0', userDB, findOptions);
  if (userDB) {
    userDB
      .createIndex({
        index: {fields: ['title', 'type']}
      })
      .then(() =>
        userDB.find({
          ...findOptions,
          skip: 0
        })
      )
      .catch(err => {
        console.log(err);
        throw err;
      })
      .then(result => {
        // console.log('series res:', result);
        dispatch(OPERATIONS.list.read.success(result.docs));
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }
};

export const fetch = id => (dispatch, getState) => {
  const {service: {userDB}} = getState();

  // console.log('series fetch:', id);
  if (userDB) {
    return userDB
      .get(id)
      .then(doc => {
        // console.log('series fetched:', doc);
        // dispatch(OPERATIONS.current.read.success(doc));
        return doc;
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }
};

const put = doc => (dispatch, getState) => {
  const {userDB} = getState().service;
  const docs = Array.isArray(doc) ? doc : [doc];

  // console.log('series put:', docs);

  return userDB
    .bulkDocs(docs)
    .catch(err => {
      console.error(err);
      throw err;
    })
    .then(response => {
      //TODO: process response array and handle errors
      // if (response.error === true) {
      //   console.error(`response: ${JSON.stringify(response)}`);
      //   return false;
      // } else {
      // console.log('put series response:', response);
      // }
      // dispatch(dbWriteSuccess());
      return true;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const remove = id => (dispatch, getState) => {
  const {userDB} = getState().service;
  // console.log('series remove:', id);

  return userDB
    .get(id).then(doc => {
      return userDB.remove(doc);
    }).then(result => {
      //TODO: process response array and handle errors
      return true;
    }).catch(err => {
      console.error(err);
      throw err;
    });
};
