import React, {Fragment} from 'react'
import {Route, Link, Switch, Redirect} from 'react-router-dom'
import Home from '../home'
import Login from '../login/Login'
import Account from '../user'
import Projects from '../projects'
import Project from '../project'
import NewProject from '../newproject'
import About from '../about'
import {connect} from "react-redux";
import Header from "../header";
import SeriesList from "../SeriesList/SeriesList";
import Series from "../series/Series";
import SeriesCreate from "../seriesCreate";
import {ROUTE} from "../../constants/general";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faAsterisk, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import 'react-router-modal/css/react-router-modal.css';
import {ModalContainer, ModalRoute} from 'react-router-modal';

library.add(fab, faCheck, faAsterisk, faTimes);

const initialState = {
  error: null,
  errorInfo: null
};

const Friends = () => <h1>Friends</h1>
const Books = () => <h1>Books</h1>

class LoggedApp extends React.Component {

  state = initialState;

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.log('error');
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    const err = this.state.error ? (<div>
      <h2>Something went wrong.</h2>
      <details style={{whiteSpace: 'pre-wrap'}}>
        {this.state.error && this.state.error.toString()}
        <br/>
        {this.state.errorInfo.componentStack}
      </details>
    </div>) : '';

    // if (this.props.online === null) {
    //   return <Redirect to={ROUTE.login}/>
    // }

    // return (
    //   <div>
    //     <Switch>
    //       <Route exact path="/friends/" component={Friends}/>
    //       <Route exact path="/books/" component={Books}/>
    //       <Redirect exact from="/" to="/friends/"/>
    //     </Switch>
    //   </div>
    // );
    return (
      <Fragment>
        {err}
        <Fragment>
          <div className="appContainer">
            <Header/>
            <nav>
              <ul>
                {/*<li key={ROUTE.home}><Link to={ROUTE.home}>Home</Link></li>*/}
                {/*<li key={ROUTE.login}><Link to={ROUTE.login}>Login</Link></li>*/}
                <li key={ROUTE.projects.root}><Link to={ROUTE.projects.root}>Projects</Link></li>
                <li key={ROUTE.series.root}><Link to={ROUTE.series.root}>Series</Link></li>
                {/*<li key="/about-us"><Link to="/about-us">About</Link></li>*/}
              </ul>
            </nav>

            <main>
              <Switch>
                {/*<Route exact path={ROUTE.home} component={Home}/>*/}
                {/*<Route exact path="/about-us" component={About}/>*/}

                <Route path={ROUTE.account.root} component={Account}/>

                <Route exact path={ROUTE.series.root} component={SeriesList}/>
                <Route exact path={ROUTE.series.create()} component={SeriesList}/>
                <Route path={ROUTE.series.id()} component={Series}/>

                <Route exact path={ROUTE.projects.root} component={Projects}/>
                <Route exact path={ROUTE.projects.create()} component={Projects}/>
                <Route path={ROUTE.projects.id()} component={Project}/>
                <Redirect exact from="/" to="/series"/>
              </Switch>
            </main>
            <footer>footer</footer>
          </div>
        </Fragment>
        {/*<Route component={ModalContainer}/>*/}
        <ModalContainer/>
      </Fragment>
    );
  }
}

function mapStateToProps({
                           // online
                         }) {
  return {
    // online
  };
}

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(LoggedApp);

