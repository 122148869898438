import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
// import { bindActionCreators } from 'redux';
// import * as userActions from '../../modules/user/actions';
import cn from 'classnames';
import * as seriesActions from "../../modules/series/actions";
import styles from './Series.module.css';
import {Route} from 'react-router-dom';
import {ROUTE} from "../../constants/general";
import SeasonList from "./seasons/SeasonList";

const initialState = {
  series: {},
  seasons: []
};

class Series extends Component {
  state = initialState;

  componentDidMount() {
    const {seriesId, fetch} = this.props;
    fetch(seriesId)
      .then(series => {
        this.setState({series})
      });
  }

  composeFiled = (name, value) => {
    return (
      <div className={styles.fieldsRow}>
        <div className={styles.fieldName}>
          {name}:
        </div>
        <div className={styles.fieldValue}>
          {value}
        </div>
      </div>
    );
  };

  render() {
    const {seriesId, history, activeSeasonId} = this.props;
    console.info('series this.props', this.props);
    const {series} = this.state;
// seasons: "false"
// sponsored: "true"
// updated: "2019-05-16T12:16:25.053Z"
    return (
      <Fragment>
        <h1>Series >> {series.title}</h1>
        <div className={styles.seriesData}>
          <img className={styles.image} src="// TODO" alt={series.title}/>
          <div className={styles.fieldsBlock}>
            {this.composeFiled('Series ID', series._id)}
            {this.composeFiled('Created', new Date(series.created).toLocaleDateString())}
            {this.composeFiled('Description', series.description)}
            {this.composeFiled('Keywords', series.keywords)}
            {this.composeFiled('Playlist level', '1 //TODO: is it always 1?')}
          </div>
        </div>
        <SeasonList activeSeasonId={activeSeasonId} seriesId={seriesId} history={history}/>
        {/*<Route path={ROUTE.series.id()} component={SeasonList}/>*/}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    seriesId: ownProps.match.params.seriesId,
    activeSeasonId: ownProps.match.params.seasonId,
    // current: state.series.current,
    // userDB: state.service.userDB
  }
};
// const mapStateToProps = (state, ownProps) => ({
//   // seriesId: ownProps.match? ownProps.match.params.seriesId : 'wtf',
//   current: state.series.current,
//   userDB: state.service.userDB
// });

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(seriesActions.fetch(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Series);
