import { ACTIONS } from './actions';

const initialState = {
  seriesId: '',
  list: [],
  current: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.list.clear: {
      return {
        ...initialState,
        seasonId: action.seasonId
      };
    }
    case ACTIONS.list.read.success: {
      return {
        ...state,
        seriesId: action.seriesId,
        list: action.list
      };
    }
    case ACTIONS.current.read.success: {
      return {
        ...state,
        current: action.current
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
