import React, {Component} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import styles from './WindowContainer.module.css';
import Button from "../Button/Button";

class WindowContainer extends Component {
  render() {
    const { children, history }= this.props;
    return (
      <div className={cn(styles.windowContainer)}>
        <div className={cn(styles.closeButtonContainer)}>
          <Button className={cn(styles.closeButton)} icon="times" onClick={() => {history.goBack();}}/>
        </div>
        {children}
      </div>
    );
  }
}

export default connect()(WindowContainer);
