import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
// import cn from 'classnames';
import * as seasonsActions from "../../../modules/seasons/actions";
import styles from './SeasonList.module.css';
import {ROUTE} from "../../../constants/general";
import Season from "../../season/Season";
import Button from "../../../components/Button/Button";
import {ModalRoute} from "react-router-modal";
import SeasonCreate from "../../seasonCreate/SeasonCreate";
import Page from "../../../components/Page/Page";

class SeasonList extends Component {

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {seriesId} = this.props;
    if (prevProps.seriesId !== seriesId) {
      this.update();
    }
  }

  update = () => {
    const {seriesId, fetchSeasons} = this.props;
    fetchSeasons(seriesId);
  };

  render() {
    const {seriesId, history, activeSeasonId} = this.props;
    const {seasons} = this.props;

    const actions = [
      <Button key="seasonCreate" onClick={() => {
        this.props.history.push(ROUTE.series.seasons.create(seriesId))
      }}>
        Add Season
      </Button>
    ];

    const items = seasons.list.map(doc => (
      <li key={doc._id+' '+''} className={styles.season}>
        <Season activeSeasonId={activeSeasonId} seasonId={doc._id} history={history}/>
      </li>
    ));

    return (
      <Page title={`Seasons (${items.length})`}>
        {/*<h2>Sub-Playlists</h2>*/}
        {actions}
        <ul className={styles.seasonList}>
          {items}
        </ul>
        <ModalRoute path={ROUTE.series.seasons.create()} component={SeasonCreate} />
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // seriesId: ownProps.match.params.seriesId,
  seasons: state.seasons,
});

const mapDispatchToProps = dispatch => ({
  fetchSeasons: seriesId => dispatch(seasonsActions.fetchSeasons(seriesId)),
  remove: id => dispatch(seasonsActions.remove(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonList);
