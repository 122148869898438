import {ACTIONS} from './actions';

const initialState = {
  serviceDB: null,
  userDB: null,
  remoteDB: null,
  sync: {
    handler: null,
    status: 'off'
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.serviceDB.success: {
      return {
        ...state,
        serviceDB: action.serviceDB
      };
    }
    case ACTIONS.serviceDB.closedDB: {
      return {
        ...state,
        serviceDB: null
      };
    }
    case ACTIONS.serviceDB.error: {
      return {
        ...state,
        serviceDB: null
      };
    }

    case ACTIONS.userDB.success: {
      return {
        ...state,
        userDB: action.userDB
      };
    }
    case ACTIONS.userDB.closedDB: {
      return {
        ...state,
        userDB: null
      };
    }
    case ACTIONS.userDB.error: {
      return {
        ...state,
        userDB: null
      };
    }

    case ACTIONS.remoteDB.success: {
      return {
        ...state,
        remoteDB: action.remoteDB
      };
    }
    case ACTIONS.remoteDB.closedDB: {
      return {
        ...state,
        remoteDB: null
      };
    }
    case ACTIONS.remoteDB.error: {
      return {
        ...state,
        remoteDB: null
      };
    }

    case ACTIONS.sync.success: {
      return {
        ...state,
        sync: {
          ...state.sync.handler,
          handler: action.handler
        }
      };
    }
    case ACTIONS.sync.cancel: {
      return {
        ...state,
        sync: {
          handler: null,
          status: 'off'
        }
      };
    }
    case ACTIONS.sync.error: {
      return {
        ...state,
        sync: {
          ...state.sync.handler,
          handler: action.handler !== undefined ? action.handler : state.sync.handler,
          status: action.status !== undefined ? action.status : state.sync.status,
        }
      };
    }

    // case ACTIONS.getUsers: {
    //   return {
    //     ...state,
    //     serviceDB: null
    //   };
    // }
    //
    default: {
      return state;
    }
  }
};

export default reducer;
