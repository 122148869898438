// import cn from 'classnames';
// import {ROUTE} from "../../constants/general";
// import Button from "../../components/Button/Button";
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as linksActions from "../../modules/links/actions";
import styles from './Video.module.css';

const initialState = {
  link: {}
};

class Link extends Component {
  state = initialState;

  componentDidMount() {
    this.updateData();
  }

  updateData = () => {
    const {linkId, fetch} = this.props;
    fetch(linkId).then(doc => {
      this.setState({link: doc})
    });
  };


  composeFiled = (name, value) => {
    return (
      <div className={styles.fieldsRow}>
        <div className={styles.fieldName}>
          {name}:
        </div>
        <div className={styles.fieldValue}>
          {value}
        </div>
      </div>
    );
  };

  render() {
    const {linkId, seasonId} = this.props;
    let {link} = this.state;

    console.info('link ' + linkId + ' this.props', this.props);
    if (link === undefined) {
      console.error('season undef. why?', link, seasonId);
      link = {};
    }

    // console.error('seriesId, seasonId, link, this', seriesId, seasonId, ROUTE.series.seasons.episodes.root(seriesId, seasonId), this.props);
    return (
      <Fragment>
        <h1>{link.linkType} {link.title}</h1>

        <div className={styles.seasonData}>
          <img className={styles.image} src="// TODO" alt={link.title}/>
          <div className={styles.fieldsBlock}>
            {this.composeFiled('Lnk ID', link._id)}
            {this.composeFiled('Created', new Date(link.created).toLocaleDateString())}
            {this.composeFiled('Description', link.description)}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // seriesId: ownProps.match.params.seriesId,
  // seriesId: state.match.params.seriesId,
  // seasonId: ownProps.match.params.seasonId
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(linksActions.fetch(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Link);
