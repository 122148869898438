import { getDB } from './general';
import {DB_TYPES, PARAM_ID} from "../../constants/pouchDB";

export const DOC_TYPES = {
  project: 'project',
  series: 'series',
  season: 'season',
  episode: 'episode',
  link: 'link',
  file: {
    media: 'file-media',
    audio: 'file-audio',
    project: 'file-project',
    video: 'file-video',
  },
  user: 'user'
};

export const EMPTY_FILE_ARRAYS = Object.assign(
  ...Object.values(DOC_TYPES.file)
    .map( val => ({ [val]: [] }) )
);

export const getNewDocument = (type, props) => {
  const curDate = new Date().toISOString();
  return {
    _id: '',
    _rev: '',
    type: type,
    created: curDate,
    updated: curDate,
    ...(props ? props : {})
  };
};

export const newDoc = (id, type, title, data) => ({
  [PARAM_ID]: id,
  type,
  title,
  data
});

export const getLocalUserDoc = (user) =>
  newDoc(user._id, DOC_TYPES.user, user.login, user);

// export const getServiceUser = (id): Promise => readData(id, DB_TYPES.service);

// export const getServiceUserList = (): Promise => (dispatch, getState) => {
//   const db = dispatch(getDB(DB_TYPES.service));
//   console.log(`db = ${db}`);
//   if (db) {
//     db.find({
//       selector: { type: DOC_TYPES.user },
//       fields: ['type', 'name']
//       // ,sort: ['name']
//     })
//       .then(result => {
//         if (!result.docs) {
//           console.error('Could not find user');
//           throw new Error('Could not find user');
//         }
//         return result.docs.map(doc => doc.data);
//       })
//       .catch(err => {
//         throw err;
//       });
//   }
// };

// export const readData = (id, dbType) =>
//   readDoc(id, dbType)
//     .then(doc => {
//       if (doc.data) {
//         return doc.data;
//       }
//       throw new Error(
//         `"data" property does not found id document with id "${id}"`
//       );
//     })
//     .catch(err => {
//       throw err;
//     });
