import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.css';

const Textarea = ({ maxChars, className, ...props }) => (
    <Fragment>
        <textarea
            {...props}
            className={cn(styles.textarea, !!maxChars && styles.limitedTextarea, className)}
        />
        {
            !!maxChars
            && (
                <div className={styles.charCounter}>
                    <div>{maxChars - String(props.value).length}</div>
                </div>
            )
        }
    </Fragment>
);

Textarea.propTypes = {
    maxChars: PropTypes.number,
    value: PropTypes.string,
    className: PropTypes.string,
};

export default Textarea;
