import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {ROUTE} from "../constants/general";

class PrivateContainer extends React.Component {

  render() {
    const {userDBInitialized, children, location} = this.props;

    if (userDBInitialized !== true) {
      return <Redirect to={{pathname: ROUTE.login, state: {from: location}}}/>
    }

    return (<Fragment>{children}</Fragment>);
  }
}

const mapStateToProps = state => {
  return {
    userDBInitialized: !!state.service.userDB
  };
};

export default connect(mapStateToProps)(PrivateContainer);
