import {getSystemAppPath, isElectron} from '../utils/system';

export const isWeb = !isElectron();

const mainPath = getSystemAppPath();

const appDirectory = `Meedi.io`;
const homePath = `${mainPath ? mainPath + '/' : ''}${appDirectory}`;
export const defaultProjectPath = `${mainPath ? mainPath + '/' : ''}Documents/${appDirectory}`;

// export const beHost = 'https://18.224.70.28';
export const beHost = 'https://meedi-io.dev01.cpcs.ws';

export const SERVICE = {
  remotePath: 'https://couchdb.meedi-io.dev01.cpcs.ws:5984', // 'https://18.224.70.28:5984'
  servicePath: homePath,
  serviceDBPath: `${homePath}/__SERVICE`,
  serviceDBName: `__SERVICE`,
  userListTableName: 'USERS'
};

console.info('const', {
  const: {
    isWeb,
    defaultProjectPath,
    mainPath,
    ...SERVICE
  }
});

export const ROUTE = {
  home: '/',
  login: '/login',
  register: '/register',
  projects: {
    root: '/projects',
    create: () => `${ROUTE.projects.root}/create`,
    id: (projectId = ':projectId') => `${ROUTE.projects.root}/v/${projectId}`,
  },
  series: {
    root: '/series',
    create: () => `${ROUTE.series.root}/create`,
    id: (seriesId = ':seriesId') => `${ROUTE.series.root}/v/${seriesId}/seasons`,
    seasons: {
      root: (seriesId = ':seriesId') => `${ROUTE.series.id(seriesId)}`,
      create: (seriesId = ':seriesId') => `${ROUTE.series.seasons.root(seriesId)}/create`,
      id: (seriesId = ':seriesId', seasonId = ':seasonId') => `${ROUTE.series.seasons.root(seriesId)}/v/${seasonId}`,
      episodes: {
        root: (seriesId = ':seriesId', seasonId = ':seasonId') =>
          `${ROUTE.series.seasons.id(seriesId, seasonId)}/episodes`,
        create: (seriesId = ':seriesId', seasonId = ':seasonId') =>
          `${ROUTE.series.seasons.episodes.root(seriesId, seasonId)}/create`,
        id: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId') =>
          `${ROUTE.series.seasons.episodes.root(seriesId, seasonId)}/v/${episodeId}`,
        links: {
          root: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId', linkType = ':linkType') =>
            `${ROUTE.series.seasons.episodes.id(seriesId, seasonId, episodeId)}/${linkType}`,
          create: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId', linkType = ':linkType') =>
            `${ROUTE.series.seasons.episodes.links.root(seriesId, seasonId, episodeId, linkType)}/create`,
          id: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId', linkType = ':linkType', linkId = ':linkId') =>
            `${ROUTE.series.seasons.episodes.links.root(seriesId, seasonId, episodeId, linkType)}/v/${linkId}`,
        }
      },
    },
  },
  account: {
    root: '/account'
  }
};
