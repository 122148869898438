import newId from '../../utils/guids';
import formatDate from '../../utils/format';
import {ID_SIZE_PROJECT} from "../../constants/ids";
import {DOC_TYPES} from "../../utils/pouchDB/documents";
import {getElectronModule} from "../../utils/system";

const fse = getElectronModule('fs-extra');

const DIRECTORY_MEDIA = 'Media';
export const SUBMIT_NEW_PROJECT = 'SUBMIT_NEW_PROJECT';

const COPY_FILE_OPTIONS = {
  overwrite: false,
  errorOnExist: true,
  preserveTimestamps: true
};


export const ACTION_TYPES = {
  projects: {
    success: 'PROJECTS_FETCH_SUCCESS',
  },
};

export const ACTIONS = {
  projects: {
    success: (list) => ({type: ACTION_TYPES.projects.success, list}),
  },
};

export const getNewId = () => (disptch, getState) => {
  const {user: {current: {_id: userId}}} = getState();
  return newId(ID_SIZE_PROJECT, userId);
};

const getProjectDirectoryName = (project) => {
  return `${project.title}_${project._id}`;
};
const getFileName = (file) => {
  const {title, _id} = file;
  const baseName = title.includes('.') ? title.match(/(.*)[.]/)[1] || '' : title;
  const extension = title.includes('.') ? title.split('.').pop() : '';

  return `${baseName}_${_id}${extension ? `.${extension}` : ''}`;
};
const getNewMediaPath = projectPath => {
  return `${projectPath}/${DIRECTORY_MEDIA}/${formatDate()}`;
};

const processProject = (project, filesMedia, user) => {
  const directory = `${user.projectPath}/${getProjectDirectoryName(project)}`;

  if (fse) {
    if (filesMedia.length) {
      const newMediaPath = getNewMediaPath(directory);
      filesMedia.forEach(file => {
        console.info('from', `${file.localProps.path}/${file.title}`);
        console.info('to', `${newMediaPath}/${getFileName(file)}`);
        fse.copySync(
          `${file.localProps.path}/${file.title}`,
          `${newMediaPath}/${getFileName(file)}`,
          COPY_FILE_OPTIONS
        );
      });
    }
  } else {
    console.warn('// TODO: Upload files to web');
  }

  return true;
};

export const addProjectToDB = (project, files) => (dispatch, getState) => {
  const {userDB} = getState().service;
  // dispatch(dbWriteStart());
  const docs = [project, ...files]
    .map(doc => {
      const result = {...doc};
      delete result.localProps;
      return result;
    });
  console.log('bulk:', docs);
  // throw new Error('let\'s stop here');
  return userDB
    .bulkDocs(docs)
    .catch(err => {
      console.log(err.toString());
      // dispatch(dbWriteError());
      throw err;
    })
    .then(response => {

      // if (response.error === true) {
      //   console.error(`response: ${JSON.stringify(response)}`);
      //   return false;
      // } else {
      console.log('bulk response:', response);
      //   return true;
      // }
      // dispatch(dbWriteSuccess());
      return true;
    })
    .catch(err => {
      console.log(err.toString());
      // dispatch(dbWriteError());
      throw err;
    });
};

export const handleSubmit = (project, filesMedia) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const {user} = getState();

    const docProject = {
      ...project,
      finishedVideos: project[DOC_TYPES.file.video].length
    };

    console.info('project 0', project, filesMedia);

    console.log('handleSubmit:', docProject);
    if (processProject(docProject, filesMedia, user)) {
      dispatch(addProjectToDB(docProject, filesMedia))
        .then(result => {
          if (result === true) {
            resolve();
          } else {
            console.error('bulk result:', result);
          }
        })
        .catch(err => reject(err));
      dispatch({
        type: SUBMIT_NEW_PROJECT,
        payload: docProject
      });
    } else {
      reject(new Error('Error while processing new project'));
    }
  });

export const fetchProjects = (options) => (dispatch, getState) => {
  const {service: {userDB}} = getState();

  if (userDB) {
    const initialOptions = {
      selector: {
        $and: [{type: {$eq: DOC_TYPES.project}}, {title: {$gt: null}}]
      },
      sort: [{title: 'asc'}],
      limit: 100
    };

    userDB
      .createIndex({
        index: { fields: ['title', 'type'] }
      })
      .then(() =>
        userDB.find({
          ...(options ? options : initialOptions),
          skip: 0
        })
      )
      .catch(err => {
        console.error('Error while fetching projects', err);
        throw err;
      })
      .then(result => {
        dispatch(ACTIONS.projects.success(result.docs));
      });
  }
};

// export const readFiles = (projectId: string): Promise => (
//   dispatch,
//   getState
// ) => {
//   // dispatch(dbReadStart());
//
//   const { userDB } = getState().service;
//
//   if (userDB) {
//     return userDB
//       .allDocs({ include_docs: true })
//       .then(result => {
//         const files = [];
//         // for (let i = 0; i < result.rows.length; i += 1) {
//         //   projects.push(Project.valueOf(result.rows[i].doc.data));
//         // }
//         result.rows.forEach(row => {
//           if (
//             row.doc.type === DOC_TYPES.file &&
//             row.doc.projectId === projectId
//           ) {
//             files.push(row.doc);
//           }
//         });
//         return files;
//       })
//       .catch(err => {
//         console.log(err.toString()); // TODO
//         throw err;
//       });
//   }
// };
