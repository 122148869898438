import {PARAM_ID} from "../../constants/pouchDB";

const ERROR_NOT_FOUND = 'not_found';

export const writeDoc = (doc, dbType) => dispatch => {
  console.log('writeDoc: 0:', dbType);
  const db = dispatch(getDB(dbType));

  if (db) {
    return db
      .get(doc[PARAM_ID])
      .catch(err => {
        if (err.name !== ERROR_NOT_FOUND) {
          throw err;
        }
        return {};
      })
      .then(foundDoc => {
        console.log('writeDoc: 3:', {foundDoc});
        const curDate = new Date().toISOString();
        return dispatch(writePreparedDoc({
          ...foundDoc,
          ...doc,
          updated: curDate,
          created: foundDoc.created ? foundDoc.created : curDate
        }, dbType));
      })
      .catch(err => {
        throw err;
      });
  }

  return null;
};

const writePreparedDoc = (doc, dbType) => dispatch => {
  const db = dispatch(getDB(dbType));

  console.log('writePreparedDoc 0: ', {dbName: db.name, doc});

  if (db) {
    return db.put(doc)
      .catch(err => {
        throw err;
      })
      .then(response => {
        // { "ok": true, "id": "mydoc", "rev": "1-A6157A5EA545C99B00FF904EEF05FD9F" }
        console.log('writePreparedDoc 1: ', {response});
        if (response.ok === true) {
          return true;
        }
        console.error(`Could not put document\n${JSON.stringify(doc)}\nResponse:\n${JSON.stringify(response)}`);
        throw new Error(`Could not put document\n${JSON.stringify(doc)}\nResponse:\n${JSON.stringify(response)}`);
      })
      .catch(err => {
        throw err;
      });
  }
  return null;
};

export const readDoc = (id, dbType) => dispatch => {
  const db = dispatch(getDB(dbType));

  if (db) {
    return db.get(id).catch(err => {
      if (err.name !== ERROR_NOT_FOUND) {
        throw err;
      }
      return {};
    });
  }
  return null;
};

export const getDB = dbType => (dispatch, getState) => {
  const db = getState().service[dbType];

  if (!db) {
    console.error(`DB "${dbType}" is not opened`);
    throw new Error(`DB "${dbType}" is not opened`);
  }
  return db;
};
