import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {ROUTE} from "../constants/general";

class LoginRequiredRoute extends React.Component {

  loginRequire = () => {
    const {userDBInitialized, user} = this.props;
    return userDBInitialized && user._rev;
  };

  render() {
    const {userDBInitialized, user, component: Component, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          return (
            this.loginRequire() ? (
              <Component {...props} />
            ) : (
              <Redirect to={{
                pathname: ROUTE.login,
                state: {from: props.location}
              }}/>
            )
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    userDBInitialized: !!state.service.userDB,
    user: state.user.current
  };
};

export default connect(mapStateToProps)(LoginRequiredRoute);
