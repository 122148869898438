import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as userActions from '../../modules/user/actions';
import {ROUTE} from "../../constants/general";
import {Field, Form, Formik} from "formik";
import {object, string} from 'yup';
import {Col, Row} from "react-flexbox-grid";
import Input from "../../components/Input/Input";
import FormButtons from "../../components/FormButtons/FormButtons";
import Button from "../../components/Button/Button";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";

const initialState = {
  login: '',
  password: '',
  localUsers: []
};

class Login extends Component {
  state = initialState;

  componentDidMount() {
    const {readUsers} = this.props;
    readUsers()
      .then(localUsers => {
        this.setState({localUsers});
        return null;
      })
      .catch(err => {
      });
  }

  handleSubmit = async (values, {setSubmitting}) => {

    // this.handleLogin(values);

    const {localUsers} = this.state;
    const {history, onLogin} = this.props;

    const foundUser = localUsers.filter(user => user.login === values.login);

    const loginResult = await onLogin({
      _id: foundUser.length ? foundUser[0]._id : '',
      login: values.login,
      password: values.password
    });

    if (loginResult) {
      if (loginResult.message === '') {
        this.setState(initialState);
        history.push(ROUTE.home);
      } else {
        alert(loginResult.message);
      }
    } else {
      alert('Login unknown error');
    }

    //process form submission here
    //done submitting, set submitting to false
    setSubmitting(false);
  };
  // handleLogin = async ({login, password}) => {
  //   const {localUsers} = this.state;
  //   const {history, onLogin} = this.props;
  //
  //   const foundUser = localUsers.filter(user => user.login === login);
  //
  //   const loginResult = await onLogin({_id: foundUser.length ? foundUser[0]._id : '', login, password});
  //   if (loginResult) {
  //     this.setState(initialState);
  //     history.push(ROUTE.home);
  //   }
  // };

  handleChooseLocalUser = e => {
    const {localUsers} = this.state;
    const foundUser = localUsers.filter(user => user._id === e.target.id);
    if (foundUser.length) {
      this.setState({login: foundUser[0].login});
    }
  };

  render() {
    if (this.props.online === true) {
      const {from} = this.props.location.state || {from: {pathname: ROUTE.home}};
      return <Redirect to={from}/>
    }

    const {
      login, password,
      localUsers
    } = this.state;
    const usersList = localUsers ? (
      <ul>
        {localUsers.map(user => (
          <li id={user._id} key={user._id} onClick={this.handleChooseLocalUser}>
            {user.login} ({user._id})
          </li>
        ))}
      </ul>
    ) : (
      ''
    );

    return (
      <Formik
        initialValues={{login, password}}
        validationSchema={object().shape({
          login: string().required().min(1).max(40),
          password: string().required().min(1)
        })}
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        render={({isSubmitting}) => {
          return (
            <Form>
              {usersList}
              <Row center="sm">
                <Col sm={6}>
                  <Field name="login" component={Input} label="Login" required/>
                  <Field name="password" type="password" component={Input} label="Password" required/>
                </Col>
              </Row>
              <FormButtons>
                {/*<Button type="reset" disabled={isSubmitting} color={Button.COLOR.SECONDARY} icon="times">*/}
                {/*Reset*/}
                {/*</Button>*/}
                <Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY} icon="check">
                  Sign in
                </Button>
              </FormButtons>
              <FormButtons>
                Or <Link to={ROUTE.register}>register</Link>
                {/*<a href="" onClick={this.handleRegister}>register</a>*/}
              </FormButtons>
            </Form>);
        }}
      />);
  }
}

const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(userActions, dispatch);
const mapDispatchToProps = dispatch => ({
  onLogin: user => dispatch(userActions.onLogin(user)),
  readUsers: () => dispatch(userActions.readUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
