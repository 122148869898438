import newId from "../../utils/guids";
import {ID_SIZE_SEASON, ID_SIZE_SERIES} from "../../constants/ids";
import {DOC_TYPES, getNewDocument} from "../../utils/pouchDB/documents";

export const ACTIONS = {
  list: {
    clear: 'SEASONS_LIST_CLEAR',
    read: {
      success: 'SEASONS_LIST_READ_SUCCESS'
    }
  },
  current: {
    read: {
      success: 'SEASONS_CURRENT_READ_SUCCESS'
    }
  }
};

const OPERATIONS = {
  list: {
    clear: seasonId => ({type: ACTIONS.list.clear, seasonId}),
    read: {
      success: (seriesId, list) => ({type: ACTIONS.list.read.success, seriesId, list})
    }
  },
  current: {
    read: {
      success: current => ({type: ACTIONS.current.read.success, current})
    }
  }
};


export const getNewId = () => (dispatch, getState) => {
  const {user: {current: {_id: userId}}} = getState();
  return newId(ID_SIZE_SEASON, `${userId}sn`);
};

export const insert = values => (dispatch, getState) => {
  const doc = getNewDocument(DOC_TYPES.season, {...values, _id: dispatch(getNewId())});
  return dispatch(put(doc));
};

export const fetchSeasons = seriesId => (dispatch, getState) => {
  const {service: {userDB}, seasons} = getState();
  // const {service: {userDB}, seasons, series: {current: {_id: seriesId}}} = getState();

  const findOptions = {
    selector: {
      $and: [
        {type: {$eq: DOC_TYPES.season}},
        {seriesId: {$eq: seriesId}},
        {title: {$gt: null}}
      ]
    },
    sort: [{title: 'asc'}],
    // limit: 100
  };

  dispatch(OPERATIONS.list.clear);

  // console.log('seasons fetch:', userDB, findOptions);
  if (userDB) {
    // console.log('seasons fetch 2:');
    return userDB
      .createIndex({
        index: {fields: ['title', 'seriesId', 'type']}
      })
      .then((result) => {
          // console.log('seasons fetch 3:', result);
          return userDB.find({
            ...findOptions,
            skip: 0
          })
        }
      )
      .catch(err => {
        console.error(err);
        throw err;
      })
      .then(result => {
        // console.log('seasons res:', result);
        dispatch(OPERATIONS.list.read.success(seriesId, result.docs));
        // return result.docs;
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }
};
// export const fetchNextPage = () => (dispatch, getState) => {
//   const {service: {userDB}, seasons, series: {current: {_id: seriesId}} } = getState();
//
//   const findOptions = {
//     selector: {
//       $and: [
//         { type: { $eq: DOC_TYPES.season } },
//         { seriesId: { $eq: seriesId } },
//         { title: { $gt: null } }
//         ]
//     },
//     sort: [{ title: 'asc' }],
//     limit: 100
//   };
//
//   console.log('seasons fetch:', findOptions);
//   if (userDB) {
//     userDB
//       .createIndex({
//         index: { fields: ['title', 'seriesId', 'type'] }
//       })
//       .then(() =>
//         userDB.find({
//           ...findOptions,
//           skip: 0
//         })
//       )
//       .catch(err => {
//         console.log(err);
//         throw err;
//       })
//       .then(result => {
//         console.log('seasons res:', result);
//         dispatch(OPERATIONS.list.read.success(seriesId, result.docs));
//       })
//       .catch(err => {
//         console.log(err);
//         throw err;
//       });
//   }
// };

export const fetch = id => (dispatch, getState) => {
  const {service: {userDB}} = getState();

  // console.log('season fetch:', id);
  if (userDB) {
    return userDB
      .get(id)
      .then(doc => {
        // console.log('season fetched:', doc);
        // dispatch(OPERATIONS.current.read.success(doc));
        return doc;
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  // const findOptions = {
  //   selector: {
  //     $and: [
  //       { type: { $in: [DOC_TYPES.file.media, DOC_TYPES.file.audio, DOC_TYPES.file.video] } },
  //       { title: { $gt: null } },
  //       { 'projectId': { $eq: projectId } }
  //     ]
  //   },
  //   sort: [{ title: 'asc' }],
  //   limit: 100
  // };
  //
  // if (userDB) {
  //   userDB
  //     .get(projectId)
  //     .then(doc => {
  //       console.log('doc:', doc);
  //       this.setState({ project: doc });
  //
  //       userDB
  //         .createIndex({
  //           index: { fields: ['title', 'type', 'projectId'] }
  //         })
  //         .then(() =>
  //           userDB.find({
  //             ...findOptions,
  //             skip: 0
  //           })
  //         )
  //         .catch(err => {
  //           console.log(err);
  //           throw err;
  //         })
  //         .then(result => {
  //           console.log('res:', result);
  //
  //           let fileState = {};
  //           Object.keys(DOC_TYPES.file).forEach(key => fileState[key] = []);
  //           result.docs.forEach(file => {
  //             fileState[file.type].push(file);
  //           });
  //
  //           this.setState(fileState);  //TODO: map to smaller view object if needed
  //         })
  //         .catch(err => {
  //           console.log(err);
  //           throw err;
  //         });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       throw err;
  //     });
  // }
};

const put = doc => (dispatch, getState) => {
  const {service: {userDB}} = getState();
  const docs = Array.isArray(doc) ? doc : [doc];

  // console.log('put season:', docs);

  return userDB
    .bulkDocs(docs)
    .catch(err => {
      console.error(err);
      throw err;
    })
    .then(response => {
      //TODO: process response array and handle errors
      response.forEach(result => {
        if (result.ok !== true) {
          console.error('put result', result);
        }
      });
      // if (response.error === true) {
      //   console.error(`response: ${JSON.stringify(response)}`);
      //   return false;
      // } else {
      // console.log('put season response:', response);
      // }
      // dispatch(dbWriteSuccess());
      return true;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const remove = id => (dispatch, getState) => {
  const {service: {userDB}} = getState();
  // console.log('remove season:', id);

  return userDB
    .get(id).then(doc => {
      return userDB.remove(doc);
    }).then(result => {
      //TODO: process response array and handle errors
      return true;
    }).catch(err => {
      console.error(err);
      throw err;
    });
};
