import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './Input.module.css';

const ValidationIcon = (props) => {
  const {
    required,
    field,
    fieldTouched,
    fieldError,
  } = props;
  let kind = '';
  if (fieldTouched && fieldError) {
    kind = 'times';
  } else if (!field.value && required) {
    kind = 'asterisk';
  } else if (required && field.value && !fieldError) {
    kind = 'check';
  }
  return kind
    ? <Icon kind={kind} className={cn(styles.validationIcon, styles[`validationIcon-${kind}`])}/>
    : '';
};

ValidationIcon.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.object,
  fieldTouched: PropTypes.bool,
  fieldError: PropTypes.string,
};

export default ValidationIcon;
