import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {ROUTE} from "../../constants/general";
import Button from "../../components/Button/Button";
import * as seriesActions from "../../modules/series/actions";
import styles from "../../components/ListContainer/ListContainer.module.css";
import cn from "classnames";
import SeriesShortView from "../series/SeriesShortView";
import {ModalRoute} from "react-router-modal";
import SeriesCreate from "../seriesCreate";

class seriesList extends Component {

  componentDidMount() {
    const {series, fetchNextPage} = this.props;
    fetchNextPage();
  }

  render() {
    const {series, fetchNextPage} = this.props;

    const header='Series';

    const actions = [
      <Button key="seriesCreate" onClick={() => {this.props.history.push(ROUTE.series.create())}}>
        Add Series
      </Button>
    ];
    const items = series.list.map(doc => (
      <li className={cn(styles.item)} key={doc._id}>
        <SeriesShortView seriesId={doc._id} callbackUpdate={() => fetchNextPage()}/>
      </li>
    ));

    return (
      <Fragment>
        <h1>{header}</h1>
        <div>{actions}</div>

        <ul className={styles.list}>
          {items}
        </ul>
        <ModalRoute path={ROUTE.series.create()} component={SeriesCreate}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  series: state.series
});

const mapDispatchToProps = dispatch => ({
  fetchNextPage: () => dispatch(seriesActions.fetchNextPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(seriesList);
