export const isElectron = () => {
  console.info('getElectron() !== null', getElectron() !== null);
  return (getElectron() !== null);
};

export const getSystemAppPath = () => {
  const process = getElectronProp('process');

  if (process) {
    let result = process.env[process.platform === 'win32' ? 'USERPROFILE' : 'HOME'];
    if (result) {
      if (result.search(/[/|\\]$/) !== -1) {
        result = result.slice(0, -1);
      }
    }
    return result;
  }
  return '';
};

// TODO: Make window.isElectron variable and check it instead of use window.require('electron') everytime
export const getElectron = () => {
  if (window.require) {
    return window.require('electron');
  }
  return null;
};
export const getElectronProp = propName => {
  const electron = getElectron();
  if (electron) {
    return electron.remote[propName];
  }
  return null;
};
export const getElectronModule = moduleName => {
  const electron = getElectron();
  if (electron) {
    const remote = electron.remote;
    return moduleName.length ? remote.require(moduleName) : remote;
  }
  return null;
};

