import newId from "../../utils/guids";
import {ID_SIZE_EPISODE, ID_SIZE_SERIES} from "../../constants/ids";
import {DOC_TYPES, getNewDocument} from "../../utils/pouchDB/documents";

export const ACTIONS = {
  read: {
    success: 'EPISODES_READ_SUCCESS'
  }
};

const OPERATIONS = {
  read: {
    success: (seasonId, list) => ({type: ACTIONS.read.success, seasonId, list})
  }
};


export const getNewId = () => (dispatch, getState) => {
  const {user: {current: {_id: userId}}} = getState();
  return newId(ID_SIZE_EPISODE, `${userId}ep`);
};

export const insert = values => (dispatch, getState) => {
  const doc = getNewDocument(DOC_TYPES.episode, {...values, _id: dispatch(getNewId())});
  return dispatch(put(doc));
};

export const fetchEpisodes = (seasonId) => (dispatch, getState) => {
  const {service: {userDB}} = getState();

  const findOptions = {
    selector: {
      $and: [
        {type: {$eq: DOC_TYPES.episode}},
        {seasonId: {$eq: seasonId}},
        {title: {$gt: null}}
      ]
    },
    sort: [{title: 'asc'}],
    // limit: 100
  };

  // console.log('ep fetch: 0', userDB, findOptions);
  if (userDB) {
    userDB
      .createIndex({
        index: {fields: ['title', 'type', 'seasonId']}
      })
      .then(() =>
        userDB.find({
          ...findOptions,
          skip: 0
        })
      )
      .catch(err => {
        console.error(err);
        throw err;
      })
      .then(result => {
        console.log('ep res:', result);
        dispatch(OPERATIONS.read.success(seasonId, result.docs));
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }
};

export const fetch = id => (dispatch, getState) => {
  const {service: {userDB}} = getState();

  console.log('ep fetch:', id);
  if (userDB) {
    return userDB
      .get(id)
      .then(doc => {
        console.log('ep fetched:', doc);
        return doc;
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }
};

const put = doc => (dispatch, getState) => {
  const {service: {userDB}} = getState();
  const docs = Array.isArray(doc) ? doc : [doc];

  console.log('ep put:', docs);

  return userDB
    .bulkDocs(docs)
    .catch(err => {
      console.error(err);
      throw err;
    })
    .then(response => {
      //TODO: process response array and handle errors
      response.forEach(result => {
        if (result.ok !== true) {
          console.error('put result', result);
        }
      });
      // if (response.error === true) {
      //   console.error(`response: ${JSON.stringify(response)}`);
      //   return false;
      // } else {
      console.log('ep put response:', response);
      // }
      // dispatch(dbWriteSuccess());
      return true;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const remove = id => (dispatch, getState) => {
  const {service: {userDB}} = getState();
  console.log('ep remove:', id);

  return userDB
    .get(id).then(doc => {
      return userDB.remove(doc);
    }).then(result => {
      //TODO: process response array and handle errors
      return true;
    }).catch(err => {
      console.error(err);
      throw err;
    });
};
