import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { bindActionCreators } from 'redux';
// import * as projectActions from '../../modules/projects/actions';
import {ROUTE} from "../../constants/general";
import WindowContainer from "../../components/WindowContainer/WindowContainer";
import {Field, Form, Formik} from "formik";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {boolean, object, string} from "yup";
import FormButtons from "../../components/FormButtons/FormButtons";
import * as seasonsActions from "../../modules/seasons/actions";
import PrivateContainer from "../../components/PrivateContainer";

const yesNoOptions = [
  {
    label: 'Yes',
    value: "true"
  },
  {
    label: 'No',
    value: "false"
  },
];

const initialState = {
  episode: {
    title: 'Season 001',
    description: 'descr 1\ndescr 2',
    keywords: 'key words',
    sponsored: "false",
    seasons: "false"
  }
};

class SeasonCreate extends Component {

  state = initialState;

  render() {
    const {seriesId, save, fetchSeasons, history} = this.props;
    const {season} = this.state;

    return (
      <PrivateContainer>
        <WindowContainer history={this.props.history}>
          <h1>New Season</h1>
          <Formik
            initialValues={season}
            validationSchema={object().shape({
              title: string().required().min(1).max(250),
              description: string(),
              keywords: string(),
              sponsored: boolean(),
              seasons: boolean()
            })}
            onSubmit={(values, {setSubmitting}) => {
              console.log('values', values);
              save({...values, seriesId}).then(() => {
                setSubmitting(false);
                fetchSeasons(seriesId);
                history.goBack();
              });
            }}
            render={({isSubmitting}) => {
              return (
                <Form>
                  <Field name="title" component={Input} label="Name" required/>
                  <Field name="description" type="textarea" component={Input} label="Description"/>
                  <Field name="keywords" component={Input} label="Keywords"/>
                  <Field name="sponsored" type="radio_buttons" component={Input} label="Sponsored"
                         options={[...yesNoOptions]}/>
                  <Field name="seasons" type="radio_buttons" component={Input} label="Seasons"
                         options={[...yesNoOptions]}/>
                  <FormButtons>
                    <Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY} icon="check">
                      Create
                    </Button>
                  </FormButtons>
                </Form>
              )
            }}
          />
        </WindowContainer>
      </PrivateContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  seriesId: ownProps.match.params.seriesId
});

const mapDispatchToProps = dispatch => ({
  save: values => dispatch(seasonsActions.insert(values)),
  fetchSeasons: seriesId => dispatch(seasonsActions.fetchSeasons(seriesId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonCreate);
