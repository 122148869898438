import {PouchDB} from '../../store';
import {DB_TYPES} from "../../constants/pouchDB";
import {SERVICE} from "../../constants/general";
import {ACTION_TYPES} from "../user/actions";
import {DOC_TYPES} from "../../utils/pouchDB/documents";
import * as seriesActions from "../series/actions";

export const ACTIONS = {
  serviceDB: {
    closedDB: 'SERVICE_DB_CLOSED',
    success: 'SERVICE_DB_SUCCESS',
    error: 'SERVICE_DB_ERROR'
  },
  userDB: {
    closedDB: 'LOCAL_DB_CLOSED',
    success: 'LOCAL_DB_SUCCESS',
    error: 'LOCAL_DB_ERROR'
  },
  remoteDB: {
    closedDB: 'REMOTE_DB_CLOSED',
    success: 'REMOTE_DB_SUCCESS',
    error: 'REMOTE_DB_ERROR'
  },
  sync: {
    cancel: 'REMOTE_SYNC_CANCEL',
    active: 'REMOTE_SYNC_ACTIVE',
    success: 'REMOTE_SYNC_SUCCESS',
    error: 'REMOTE_SYNC_ERROR'
  }
  // openDB: 'SERVICE_INIT_OPEN',
  // closedDB: 'SERVICE_DB_CLOSED',
  // success: 'SERVICE_DB_SUCCESS',
  // error: 'SERVICE_DB_ERROR',
  // getUsersSuccess: 'SERVICE_DB_GET_USERS_SUCCESS',
  // getUsersError: 'SERVICE_DB_GET_USERS_ERROR'
};

const METHODS = {
  service: {
    actionClose: () => ({type: ACTIONS.serviceDB.closedDB}),
    actionSuccess: serviceDB => ({type: ACTIONS.serviceDB.success, serviceDB}),
    actionError: () => () => ({type: ACTIONS.serviceDB.error}),
    closeDB: () => async (dispatch, getState) => {
      const db = getState()[DB_TYPES.service];
      await closeDB(db);
      this.actionClose();
    },
    createIndex: (db) => showDbInfo(db) // TODO: scroll down to view code in comments
  },
  user: {
    actionClose: () => ({type: ACTIONS.userDB.closedDB}),
    actionSuccess: userDB => ({type: ACTIONS.userDB.success, userDB}),
    actionError: () => () => ({type: ACTIONS.userDB.error}),
    closeDB: () => async (dispatch, getState) => {
      const db = getState()[DB_TYPES.user];
      await closeDB(db);
      this.actionClose();
    },
    createIndex: (db) => showDbInfo(db) // TODO: scroll down to view code in comments
  },
  remote: {
    actionClose: () => ({type: ACTIONS.remoteDB.closedDB}),
    actionSuccess: remoteDB => {
      return {type: ACTIONS.remoteDB.success, remoteDB};
    },
    actionError: () => () => ({type: ACTIONS.remoteDB.error}),
    closeDB: () => async (dispatch, getState) => {
      const db = getState()[DB_TYPES.remote];
      await closeDB(db);
      this.actionClose();
    },
    createIndex: (db) => showDbInfo(db) // TODO: scroll down to view code in comments
  },
  sync: {
    cancel: () => ({type: ACTIONS.sync.cancel}),
    active: () => ({type: ACTIONS.sync.active, status: 'active'}),
  }
};

const getDocTypeById = (docId, userId) => {
  const id = docId.slice(userId.length);
  if (id.slice(0, 2) === 'sr') {
    return DOC_TYPES.series;
  }
  return undefined;
};
const syncOnActive = () => async (dispatch, getState) => {
  // replicate resumed (e.g. new changes replicating, user went back online)
  console.info('sync active');
  //TODO: Here we can show status somewhere
};
const syncOnChange = info => async (dispatch, getState) => {
  console.info('sync change', {info});
  if (info.direction === 'pull' && info.change && info.change.ok === true && info.change.docs_written > 0) {
    const {user: {current: {_id: userId}}} = getState();

    const types = {};
    info.change.docs.forEach(doc => {
      if (doc._deleted) {
        types[getDocTypeById(doc._id, userId)] = true;
      } else {
        types[doc.type] = true;
      }
    });

    if (types[DOC_TYPES.series]) {
      dispatch(seriesActions.fetchNextPage());
    }
    //TODO: actions on sync different types of docs
  }
};
const cancelSyncDB = () => async (dispatch, getState) => {
  const {service: {sync: {handler}}} = getState();
  if (handler) {
    try {
      handler.cancel();
      dispatch(METHODS.sync.cancel());
    } catch (err) {
      console.error('Canceling sync', err);
    }
  }
};
export const initSyncDB = () => async (dispatch, getState) => {
  await dispatch(cancelSyncDB());

  const {service: {userDB, remoteDB}} = getState();

  if (userDB && remoteDB) {
    // do one way, one-off sync from the server until completion
    userDB.replicate.from(remoteDB)
      .on('change', info => dispatch(syncOnChange({change: info, direction: 'pull'})))
      .on('complete', info => {
      // then two-way, continuous, retriable sync
      const opts = {live: true, retry: true};
      const handler = userDB.sync(remoteDB, opts)
        .on('change', info => dispatch(syncOnChange(info)))
        .on('paused', err => { // replication paused (e.g. replication up to date, user went offline)
          console.info('sync paused', {err});
        })
        .on('active', () => dispatch(syncOnActive()))
        .on('denied', err => { // a document failed to replicate (e.g. due to permissions)
          console.info('sync denied', {err});
        })
        .on('complete', info => { // handle complete
          console.info('sync complete', {info});
        })
        .on('error', err => {
          console.info('sync error', {err});
        });
      console.info('handler', handler);
      dispatch({type: ACTIONS.sync.success, handler});
    });
  }
};


const getUserIdFromState = () => (dispatch, getState) => {
  const {
    user: {
      web: {id: webId},
      local: {id: localId},
      current: {id: currentId}
    }
  } = getState();
  const id = webId ? webId : (localId ? localId : currentId);
  return `u${id}`;
};
export const initUserDB = () => async (dispatch, getState) => {
  const dbName = dispatch(getUserIdFromState());
  const dbUrl = `${SERVICE.servicePath}/${dbName}`;
  return await dispatch(initDB(dbUrl, METHODS.user));
};

export const initRemoteDB = () => async (dispatch, getState) => {
  const dbName = dispatch(getUserIdFromState());
  const dbUrl = `${SERVICE.remotePath}/${dbName}`;
  return await dispatch(initDB(dbUrl, METHODS.remote, true));
};

export const initServiceDB = () => async dispatch => {
  const dbUrl = `${SERVICE.servicePath}/${SERVICE.serviceDBName}`;
  return await dispatch(initDB(dbUrl, METHODS.service));
};

const loginDB = async (db, name, password) => {
  if (db) {
    const user = {name, password};
    const ajaxOpts = {
      ajax: {
        headers: {
          Authorization: 'Basic ' + window.btoa(user.name + ':' + user.password)
        }
      }
    };

    try {
      const res = await db.logIn(user.name, user.password, ajaxOpts);
      if (!!res && res.ok === true) {
        console.info(`Logged in db ${db.name}`);
        return true;
      } else {
        console.error(`Error while login to db ${db.name}. Response`, res);
      }
    } catch (err) {
      console.error(`Error while login to db ${db.name}`, err);
    }
  } else {
    console.error(`db is undefined`);
  }

  return false;
};
const printDbInfo = async db => {
  if (db) {
    try {
      const info = await db.info();
      console.info(`db ${db.name}`, {info});
    } catch (err) {
      console.error(`Error while getting db info for ${db.name}`, err);
      console.error('db info error', err);
    }
  } else {
    console.error(`db is undefined`);
  }
  console.info('db.allDocs()', {allDocs: await db.allDocs({ include_docs: true })});
};

const initDB = (dbName, methods, isRemote) => async dispatch => {
  await methods.closeDB();

  let db;
  try {
    db = new PouchDB(dbName);
    let loggedIn = true;
    if (isRemote === true) {
      const loggedIn = await loginDB(db, 'meedi', 'AmcAByX2shTFLLFy');  // TODO: Remove hardcode
    }
    if (loggedIn) {
      await printDbInfo(db); // need to call db.info() for remote DB to create it if it absent
      // methods.createIndex(db);
      dispatch(methods.actionSuccess(db));
    } else {
      db = null;
    }
  } catch (err) {
    console.error(`Can't open db "${dbName}"`, err);
    dispatch(methods.actionError());

    await closeDB(db);

    db = null;
    // TODO dispatch( Error to somewhere );
  }

  return db;
};

const closeDB = async db => {
  if (db) {
    await db.close();
  }
};

const showDbInfo = db => {
  db.info()
    .then(info => {
      console.log(info);
      return null;
    })
    .catch(() => null);
};

// const createIndexServiceDB = (db: PouchDB) => null;
// // TODO
// // db
// //   .createIndex({
// //     index: {
// //       fields: ['type', 'name']
// //       // , name: 'type'
// //     }
// //   })
// //   .then(result => {
// //     console.log('index result:', JSON.stringify(result));
// //     if (result) {
// //       if (result.result !== 'created' && result.result !== 'exists') {
// //         throw new Error('Can not create "type" index');
// //       }
// //     }
// //     return null;
// //   })
// //   .catch(err => {
// //     throw err;
// //   });
//
// const createIndexUserDB = (db: PouchDB) => null;
// // TODO
// // db
// //   .createIndex({
// //     index: {
// //       fields: ['type', 'title'],
// //       name: 'type'
// //     }
// //   })
// //   .then(result => {
// //     if (result) {
// //       if (result.result !== 'created' && result.result === 'exists') {
// //         throw new Error('Can not create "type" index in user');
// //       }
// //     }
// //     return null;
// //   })
// //   .catch(err => {
// //     throw err;
// //   });
