import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {ROUTE} from "../../constants/general";
import {ModalRoute} from "react-router-modal";
import Button from "../../components/Button/Button";
import * as linksActions from "../../modules/links/actions";
import styles from "./LinkList.module.css";
import Page from "../../components/Page/Page";
import Link from "./Link";
import LinkCreate from "./LinkCreate";

class LinkList extends Component {

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {episodeId} = this.props;
    if (prevProps.episodeId !== episodeId) {
      this.update();
    }
  }

  update = () => {
    const {episodeId, linkType, fetchLinks} = this.props;
    fetchLinks(episodeId, linkType);
  };

  handleLinkCreate = () => {
    const {seriesId, seasonId, episodeId, linkType, history} = this.props;
    history.push(ROUTE.series.seasons.episodes.links.create(seriesId, seasonId, episodeId, linkType));
  };

  render() {
    const {seriesId, seasonId, episodeId, linkType, links} = this.props;

    const actions = [
      <Button key="linkCreate" onClick={this.handleLinkCreate}>+ {linkType}</Button>
    ];

    console.log('links', links);

    // const items = [];
    const items = links.list.map(doc => (
      <li key={doc._id} className={styles.link}>
        {doc.title}  (id: {doc._id})
        {/*<Link linkId={doc._id}/>*/}
      </li>
    ));


    return (
      <Page title={`Links-${linkType} (${items.length})`}>
        {actions}
        <ul className={styles.linkList}>
          {items}
        </ul>
        <ModalRoute path={ROUTE.series.seasons.episodes.links.create()} component={LinkCreate} />
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  seriesId: ownProps.match.params.seriesId,
  seasonId: ownProps.match.params.seasonId,
  episodeId: ownProps.match.params.episodeId,
  linkType: ownProps.match.params.linkType,
  links: state.links
});

const mapDispatchToProps = dispatch => ({
  fetchLinks: (episodeId, linkType) => dispatch(linksActions.fetchLinks(episodeId, linkType)),
  remove: id => dispatch(linksActions.remove(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkList);
