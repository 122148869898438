import React from 'react';
import PropTypes from 'prop-types';
import styles from './Page.module.css';

const Page = ({ children, title }) => (
    <div className={styles.container}>
        <div className={styles.contentHeader}>
            <h1 className={styles.title}>{title}</h1>
        </div>
        {children}
    </div>
);

Page.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,

};

export default Page;
