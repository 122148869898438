import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
// import styles from './Icon.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ kind, className }) => (
  <Fragment>
    <FontAwesomeIcon icon={kind} className={className}/>
    {/*<span className={cn(styles.icon, styles[`icon-${kind}`], className)} />*/}
  </Fragment>
);

Icon.propTypes = {
    kind: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Icon;
