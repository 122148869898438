import React from 'react';
import {connect} from 'react-redux';
import {ROUTE} from "../../constants/general";
import EpisodeList from "./EpisodeList";
import {Route} from "react-router-dom";

class EpisodeListDetailRoute extends React.Component {
  render() {
    return (
      <Route path={ROUTE.series.seasons.episodes.root()} component={EpisodeList}/>
    );
  }
}

export default connect()(EpisodeListDetailRoute);
