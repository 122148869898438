import Request from './Request';

class Api {
  _token = null;

  sendDefaultRequest(method, url, data) {
    const request = new Request(method, url, data);
    request.token = this.token;
    // request.setHeader('Content-Type', 'application/json');
    return request.execute()
      .then(response => response)
      .catch(err => {
        // if (err.status === 401 && response.url.indexOf(`/${API.logout}`) < 0) {
        //     logout(ERRORS.user.sessionExpired);
        // }
        throw err;
      });
  }

  get(url, data) {
    return this.sendDefaultRequest('get', url, data);
  }

  post(url, data) {
    return this.sendDefaultRequest('post', url, data);
  }

  put(url, data) {
    return this.sendDefaultRequest('put', url, data);
  }

  patch(url, data) {
    return this.sendDefaultRequest('patch', url, data);
  }

  delete(url, data) {
    return this.sendDefaultRequest('delete', url, data);
  }

  // async upload(url, file, params = {}) {
  //   const data = new FormData();
  //   data.append(params.uploadParamName || 'file', file);
  //
  //   const request = new Request('post', url);
  //   request.params.body = data;
  //   request.token = this.token;
  //
  //   try {
  //     return await request.execute();
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  set token(token) {
    this._token = token;
  }

  get token() {
    return this._token;
  }

}

export default new Api();
