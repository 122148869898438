// import { bindActionCreators } from 'redux';
// import * as projectActions from '../../modules/projects/actions';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ROUTE} from "../../constants/general";
import WindowContainer from "../../components/WindowContainer/WindowContainer";
import {Field, Form, Formik} from "formik";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {object, string} from "yup";
import FormButtons from "../../components/FormButtons/FormButtons";
import PrivateContainer from "../../components/PrivateContainer";
import * as linkActions from "../../modules/links/actions";
import {debounce} from "lodash";
import {Combobox} from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css';
import {DOC_TYPES} from "../../utils/pouchDB/documents";

const initialState = {
  link: {
    title: '',
    fileId: '',
    description: ''
  },
  files: [],
  busy: false
};

class LinkCreate extends Component {

  state = initialState;

  fetchFiles = (value, type) => {
    console.info('fetchFiles', this.state, value);
    const {fetchFiles} = this.props;
    fetchFiles(value, type)
      .then(result => {
        this.setState(state => ({
          files: result,
          busy: false
        }));
      })
      .catch(err => {
        console.error(err);
        this.setState(state => ({
          files: [],
          busy: false
        }));
      });
  };

  debouncedFetchFiles = debounce(this.fetchFiles, 500);

  handleTitleChange = value => {
    // const {fetchFiles} = this.props;
    console.info('handleTitleChange', value);
    if ((typeof value === "object") && (value !== null)) {
      console.info('obj');
      this.setState(state => ({
        link: {...state.link, fileId: value._id, title: value.title},
        busy: false
      }));
    } else {
      console.info('search', this);
      if (value.length > 0) {
        this.setState({busy: true});
        this.debouncedFetchFiles(value, DOC_TYPES.file.media);
      } else {
        if (this.state.files.length) {
          this.setState({files: []});
        }
      }
    }
  };
  // handleTitleSelect = (dataItem, metadata) => {
  //   console.info('handleCmbSelect', dataItem, metadata);
  //   //   {
  //   //     videoSearch: {
  //   //       ...state.videoSearch,
  //   //       value
  //   //     }
  //   //   }
  //   // ));
  //   // this.debouncedFindVideos(value);
  // };

  render() {
    const {seriesId, seasonId, episodeId, linkType, save, fetchLinks, history} = this.props;
    const {link, files, busy} = this.state;

    return (
      <PrivateContainer>
        <WindowContainer history={this.props.history}>
          <h1>Add {linkType}</h1>
          <Formik
            initialValues={link}
            enableReinitialize={true}
            validationSchema={object().shape({
              title: string().required().min(1).max(250),
              description: string(),
              keywords: string()
            })}
            validate={values => {
              let errors = {};

              if (!values.fileId) {
                errors.title = 'video not found';
              }
              if (!values.fileId) {
                errors.title = 'Required';
              }

              return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
              console.log('lnk values', {
                ...values,
                seriesId,
                seasonId,
                episodeId
              });
              save(seriesId, seasonId, episodeId, linkType, values).then(() => {
                setSubmitting(false);
                fetchLinks(episodeId, linkType);
                history.goBack();
              });
            }}
            render={({isSubmitting}) => {
              return (
                <Form>
                  <Field
                    name="title"
                    placeHolder="search for video ..."
                    busy={busy}
                    component={Combobox}
                    onChange={this.handleTitleChange}
                    onSelect={this.handleTitleSelect}
                    data={files}
                    valueField="title"
                    textField="title"/>
                  <Field name="fileId" placeholder="here will be the search for media" component={Input} label="file Id"
                         required/>
                  <Field name="description" type="textarea" component={Input} label="Description"/>
                  <FormButtons>
                    <Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY} icon="check">
                      Add
                    </Button>
                  </FormButtons>
                </Form>
              )
            }}
          />
        </WindowContainer>
      </PrivateContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  seriesId: ownProps.match.params.seriesId,
  seasonId: ownProps.match.params.seasonId,
  episodeId: ownProps.match.params.episodeId,
  linkType: ownProps.match.params.linkType
});

const mapDispatchToProps = dispatch => ({
  save: (seriesId, seasonId, episodeId, linkType, values) => dispatch(linkActions.insert({
    ...values,
    seriesId,
    seasonId,
    episodeId,
    linkType
  })),
  fetchLinks: (episodeId, linkType) => dispatch(linkActions.fetchLinks(episodeId, linkType)),
  fetchFiles: (title, type) => dispatch(linkActions.fetchFiles(title, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkCreate);
