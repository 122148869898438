import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './FormButtons.module.css';

const FormButtons = ({ children, topMargin }) => (
    <div className={cn(styles.root, topMargin && styles.topMargin)}>
        {children}
    </div>
);

FormButtons.propTypes = {
    children: PropTypes.any,
    topMargin: PropTypes.bool,
};

export default FormButtons;
