import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './Project.css';
import * as projectActions from '../../modules/projects/actions';
import {DOC_TYPES, EMPTY_FILE_ARRAYS} from '../../utils/pouchDB/documents';
import './index.css'
import {ROUTE} from "../../constants/general";

const initialState = {
  project: {},
  ...EMPTY_FILE_ARRAYS
};

class Project extends Component {

  state = initialState;

  componentDidMount() {
    this.readProject();
  }

  readProject = () => {
    const { userDB, projectId } = this.props;

    const findOptions = {
      selector: {
        $and: [
          { type: { $in: Object.values(DOC_TYPES.file) } },
          { title: { $gt: null } },
          { 'projectId': { $eq: projectId } }
        ]
      },
      sort: [{ title: 'asc' }],
      limit: 100
    };

    userDB.allDocs({ include_docs: true }).then(docs => {
      console.log('docs:', docs);
    });

    if (userDB) {
      userDB
        .get(projectId)
        .then(doc => {
          console.log('doc:', doc);
          this.setState({ project: doc });

          userDB
            .createIndex({
              index: { fields: ['title', 'type', 'projectId'] }
            })
            .then(() =>
              userDB.find({
                ...findOptions,
                skip: 0
              })
            )
            .catch(err => {
              console.log(err);
              throw err;
            })
            .then(result => {
              console.log('res:', result);

              let fileState = {};
              Object.values(DOC_TYPES.file).forEach(val => fileState[val] = []);
              result.docs.forEach(file => {
                fileState[file.type].push(file);
              });

              this.setState(fileState);  //TODO: map to smaller view object if needed
            })
            .catch(err => {
              console.log(err);
              throw err;
            });
        })
        .catch(err => {
          console.log(err);
          throw err;
        });
    }
  };

  titleOnChange = e => {
    const {value} = e.target;
    this.setState(state => {
      return {
        project: {...state.project, title: value}
      }
    });
  };

  descriptionOnChange = e => {
    const {value} = e.target;
    this.setState(state => {
      return {
        project: {...state.project, description: value}
      }
    });
  };

  handleSubmitProject = e => {
    const { handleSubmit } = this.props;
    const { project, [DOC_TYPES.file.media]: filesMedia } = this.state;
    console.log('submit:', project);
    handleSubmit(project, []) //TODO:, [...filesMedia])
      .then(result => {
        this.readProject();
      })
      .catch(error => {
        alert(error.message);
        console.error(error.message);
      });
  };

  render() {
    const {
      project,
      [DOC_TYPES.file.media]: filesMedia,
      [DOC_TYPES.file.audio]: filesAudio,
      [DOC_TYPES.file.video]: filesVideo
    } = this.state;

    const medias = filesMedia.map(fileMedia => (
      <li className={styles.mediaItem} key={fileMedia._id}>
        <span className={styles.mediaName}>{fileMedia.title}</span>
        {' '}
        <span className={styles.mediaId}>{fileMedia._id}</span>
        {/* <span className={styles.mediaAction}>img</span> */}
        {/* <span className={styles.mediaAction}>img</span> */}
      </li>
    ));

    return (
      <div>
        <h1>
          Video Projects {'>>'} {project.title}
        </h1>
        <form>
        <div id="id">
          <span className={styles.label}>Project ID:</span>
          <span className={styles.value}>{project._id}</span>
          <span className={styles.value}><br/>{'     '}{this.state._rev}</span>
        </div>
        <div id="created">
          <span className={styles.label}>Created:</span>
          {new Date(project.created).toLocaleDateString()}
        </div>
        <div id="FinishedVideos">
          <span className={styles.label}>Finished videos:</span>
          {project.finishedVideos}
        </div>
        <div id="name">
          {/*<span className={styles.label}>Video Project Title:</span>*/}
          <label className={styles.label} htmlFor="projectName">Video Project Title:</label>
          <input type="text" id="projectName" name="projectName" onChange={this.titleOnChange} value={project.title}/>
        </div>
        <div id="description">
          {/*<span className={styles.label}>Video Project Description:</span>*/}
          <label style={{ width: '40%', textAlign: 'right'}} htmlFor="projectDescription">Project Description: </label>
          <textarea
            style={{ width: '60%' }}
            id="projectDescription"
            name="projectDescription"
            onChange={this.descriptionOnChange}
            value={project.description}
          />
        </div>
        <h2>Media</h2>
        <ul className={styles.mediaList}>{medias}</ul>
        </form>
        <Link to={ROUTE.projects.root}>Back</Link>
        <input
          type="button"
          value="Save"
          onClick={this.handleSubmitProject}
        />
      </div>
    );
  }
}

// const s = {
//   match: { path: '/project', url: '/project', isExact: false, params: {} },
//   location: { pathname: '/project/noYQozt8', search: '', hash: '' },
//   history: {
//     length: 6,
//     action: 'PUSH',
//     location: { pathname: '/project/noYQozt8', search: '', hash: '' }
//   }
// };

const mapStateToProps = (state, ownProps) => ({
  projectId: ownProps.match.params.projectId,
  userDB: state.service.userDB
  // project: state.projects.find(
  //   project => project.id === ownProps.match.params.id
  // )
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(projectActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Project);
