import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import styles from './Projects.css';
import {DOC_TYPES} from '../../utils/pouchDB/documents';
import {ROUTE} from "../../constants/general";
import {ModalRoute} from "react-router-modal";
import NewProject from '../newproject'
import * as projectsActions from "../../modules/projects/actions";
// import { bindActionCreators } from 'redux';
// import * as projectActions from '../actions/projectList';

const initialState = {};

const findOptions = {
  selector: {
    $and: [{type: {$eq: DOC_TYPES.project}}, {title: {$gt: null}}]
  },
  sort: [{title: 'asc'}],
  limit: 100
};

function getProjectPath(id) {
  return ROUTE.projects.id(id);
}

class Projects extends Component {
  state = initialState;

  componentDidMount() {
    const {fetchProjects} = this.props;
    console.info('fetchProjectsm', fetchProjects);
    fetchProjects(findOptions);
  }

  render() {
    const {projects} = this.props;

    const elements = projects.map(project => (
      <li key={project._id} className={styles.project}>
        <Link to={getProjectPath(project._id)}>
          <div className={styles.baseInfo}>
            <div className={styles.name}>{project.title}</div>
            <div>Created: {new Date(project.created).toLocaleDateString()}</div>
            <div>Finished videos: {project.finishedVideos}</div>
          </div>
          <div className={styles.pid}>PID: {project._id}</div>
        </Link>
      </li>
    ));

    return (
      <Fragment>
        <h1>
          Projects <span className={styles.count}>({projects.length})</span>
        </h1>
        <ul>{elements}</ul>
        <Link to={ROUTE.home}>Back</Link>{' '}
        <Link to={ROUTE.projects.create()}>New Project</Link>

        <ModalRoute exact path={ROUTE.projects.create()} component={NewProject}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects.list,
});

const mapDispatchToProps = dispatch => ({
  fetchProjects: (options) => dispatch(projectsActions.fetchProjects(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
