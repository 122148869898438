import React, {Component} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import styles from './SeriesShortView.module.css';
import {Col, Row} from "react-flexbox-grid";
import {Link} from "react-router-dom";
import * as seriesActions from "../../modules/series/actions";
import {ROUTE} from "../../constants/general";
import Button from "../../components/Button/Button";

const initialState = {
  series: {}
};

const fieldMap = {
  'created': 'Created',
  'numberOfVideos': 'Number of Videos'
};

class SeriesShortView extends Component {
  state = initialState;

  componentDidMount() {
    const {seriesId, fetch} = this.props;
    fetch(seriesId).then(series => this.setState({series}));
  }

  handleSeriesDelete = id => {
    const {callbackUpdate, remove} = this.props;
    const {series: doc} = this.state;

    remove(doc._id).then(() => {
      if (callbackUpdate) {
        callbackUpdate();
      } else {
        this.setState(initialState);
      }
    });
  };

  render() {
    const {series: doc} = this.state;

    const fields = Object.keys(fieldMap).map(key =>
      <div key={key}>{fieldMap[key]}: {doc[key]}</div>
    );
    fields.push(<div key="PlaylistLevel">Playlist level: 1</div>);

    return (
      <Link to={ROUTE.series.id(doc._id)}>
        <div className={styles.container}>
          <img className={styles.image} src="// TODO"/>
          <div className={styles.dataBlock}>
            <Row middle="sm">
              <Col sm={7}>
                  <h3>{doc.title}</h3>
                  <div className={cn(styles.details)}>
                    {fields}
                  </div>
              </Col>
              <Col sm={3} className={cn(styles.wrap)}>
                SRID:{doc._id}
              </Col>
              <Col sm={2}>
                <Button key="seriesDelete" onClick={this.handleSeriesDelete}>del</Button>
              </Col>
            </Row>
          </div>
        </div>
      </Link>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(seriesActions.fetch(id)),
  remove: id => dispatch(seriesActions.remove(id))
});

export default connect(undefined, mapDispatchToProps)(SeriesShortView);
