import nanoId from 'nanoid';

export default function newId(size, prefix) {
  return (prefix ? prefix : '') + nanoId(size);
}

// public void TestRandomIdGenerator()
// {
//   // create five IDs of six, base 62 characters
//   for (int i=0; i<5; i++) Console.WriteLine(RandomIdGenerator.GetBase62(6));
//
//   // create five IDs of eight base 36 characters
//   for (int i=0; i<5; i++) Console.WriteLine(RandomIdGenerator.GetBase36(8));
// }
//
// public static class RandomIdGenerator
// {
//   private static char[] _base62chars =
//   "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
//     .ToCharArray();
//
//   private static Random _random = new Random();
//
//   public static string GetBase62(int length)
// {
//   var sb = new StringBuilder(length);
//
//   for (int i=0; i<length; i++)
//   sb.Append(_base62chars[_random.Next(62)]);
//
//   return sb.ToString();
// }
//
// public static string GetBase36(int length)
// {
//   var sb = new StringBuilder(length);
//
//   for (int i=0; i<length; i++)
//   sb.Append(_base62chars[_random.Next(36)]);
//
//   return sb.ToString();
// }
// }
