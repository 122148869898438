import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import styles from './NewProject.css';
import newId from '../../utils/guids';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as projectActions from '../../modules/projects/actions';
import {DOC_TYPES, EMPTY_FILE_ARRAYS, getNewDocument} from "../../utils/pouchDB/documents";
import {ID_SIZE_FILE} from "../../constants/ids";
import {ROUTE} from "../../constants/general";
import {getElectronProp} from "../../utils/system";
// import {uniq} from 'lodash';

const dialog = getElectronProp('dialog');

const initialState = {
  project: {
    ...getNewDocument(DOC_TYPES.project),
    title: 'Project 001',
    description: 'test\ntest',
    ...EMPTY_FILE_ARRAYS
  },
  ...EMPTY_FILE_ARRAYS,
  backupToCloud: false
};

class NewProject extends Component {

  state = initialState;
  inputOpenFileMediaRef = React.createRef();

  componentDidMount() {
    const {getNewId} = this.props;
    this.setState(state => {
        return {
          project: {
            ...state.project,
            _id: getNewId()
          }
        }
      }
    );
  }

  handleSubmitProject = e => {
    const {history, handleSubmit, fetchProjects} = this.props;
    const {project,
      [DOC_TYPES.file.media]:filesMedia,
      [DOC_TYPES.file.audio]:filesAudio,
      [DOC_TYPES.file.video]:filesVideo,
    } = this.state;
    handleSubmit(project, [...filesMedia, ...filesAudio, ...filesVideo])
      .then(result => {
        this.setState(initialState);
        fetchProjects()
        history.push(ROUTE.projects.root);
      })
      .catch(error => {
        alert(error.message);
        console.error(error.message);
      });
  };

  titleOnChange = e => {
    const {value} = e.target;
    this.setState(state => {
      return {
        project: {...state.project, title: value}
      }
    });
  };
  descriptionOnChange = e => {
    const {value} = e.target;
    this.setState(state => {
      return {
        project: {...state.project, description: value}
      }
    });
  };
  handleBackupToCloudChange = e => {
    this.setState({backupToCloud: e.target.value});
  };

  newFileObject = (type, {name, size}) => {
    const {userId} = this.props;
    const {project} = this.state;
    const matches = name.match(/(.*)[/\\]/);

    return getNewDocument(type,
      {
        _id: newId(ID_SIZE_FILE, userId),
        title: name.split('\\').pop().split('/').pop(),
        projectId: project._id,
        size,
        localProps: {
          path: matches ? matches[1] : ''
        }
      },
    );
  };

  addFiles = (files, type, isWeb) => {
    if (files && files.length) {
      console.log('files = ', files, isWeb);
      let newFiles = [];
      if (isWeb) {
        newFiles = files.map(file => this.newFileObject(type, file));
      } else {
        newFiles = files.map(fullName => this.newFileObject(type, {name: fullName}));
      }

      this.setState(state => {
        return {
          project: {
            ...state.project,
            [type]: [
              ...state.project[type],
              ...newFiles.map(doc => doc._id)
            ]
          },
          [type]: [...state[type], ...newFiles]
        }
      });
    }
  };

  // TODO: Electron
  handleAddFileMedia = e => {
    if (dialog) {
      console.info('dialog', dialog, (dialog));
      dialog.showOpenDialog(
        {properties: ['openFile', 'multiSelections']},
        files => {
          this.addFiles(files, DOC_TYPES.file.media, false);
        }
      );
    } else {
      this.inputOpenFileMediaRef.current.click();
    }
  };
  // TODO: Web
  handleAddFileMediaInputChange = e => {
    this.addFiles([...e.target.files], DOC_TYPES.file.media, true);
  };

  render() {

    const {
      backupToCloud,
      project,
      [DOC_TYPES.file.media]: filesMedia,
      [DOC_TYPES.file.project]: filesProject,
      [DOC_TYPES.file.audio]: filesAudio,
      [DOC_TYPES.file.video]: filesVideo
    } = this.state;

    console.info('this.state', this.state);

    const filesMediaItems = filesMedia.map(file => (
      <li key={file._id}>{file.title}{' '}({file._id})</li>
    ));
    const filesProjectItems = filesProject.map(file => (
      <li key={file._id}>{file.title}{' '}({file._id})</li>
    ));
    const filesAudioItems = filesAudio.map(file => (
      <li key={file._id}>{file.title}{' '}({file._id})</li>
    ));
    const filesVideoItems = filesVideo.map(file => (
      <li key={file._id}>{file.title}{' '}({file._id})</li>
    ));

    return (
      <div className={styles.newProject}>
        <h1>Okay, lets create a new project</h1>
        <form>
          <div>
            <label htmlFor="projectName">* Project Name: </label>
            <input
              type="text"
              id="projectName"
              name="projectName"
              onChange={this.titleOnChange}
              value={project.title}
            />
          </div>
          <div>
            <label htmlFor="filesList" className={styles.top}>
              * Media:
            </label>
            <div className={styles.inlineBlock}>
              {filesMediaItems.length > 0 ? (
                <ul id="filesList">{filesMediaItems}</ul>
              ) : (
                ''
              )}

              {/* <label className={styles.fileUpload}> */}
              {/* <input type="file" /> */}
              {/* <span>Upload </span> */}
              {/* <svg */}
              {/* xmlns="http://www.w3.org/2000/svg" */}
              {/* height="0.8em" */}
              {/* viewBox="0 0 4 4" */}
              {/* > */}
              {/* <path d="M2 1l2 2h-4l2 -2" /> */}
              {/* </svg> */}
              {/* </label> */}
              <input type="button" value="Add" onClick={this.handleAddFileMedia}/>
              <input
                ref={this.inputOpenFileMediaRef}
                type="file"
                multiple="multiple"
                style={{display: 'none'}}
                onChange={this.handleAddFileMediaInputChange}
              />

              {/* <label className={styles.fileUpload}> */}
              {/* <input type="file" multiple name="files[]" required onChange={this.handleAddFile} /> */}
              {/* <span>Add </span> */}
              {/* <svg */}
              {/* xmlns="http://www.w3.org/2000/svg" */}
              {/* height="0.8em" */}
              {/* viewBox="0 0 5 5" */}
              {/* > */}
              {/* <path d="M2 0v5h1v-5h-1 M0 2v1h5v-1h-5" /> */}
              {/* </svg> */}
              {/* </label> */}

              <label className={styles.container} htmlFor="backupToCloud">
                Backup to cloud{' '}
                <input
                  type="checkbox"
                  value={backupToCloud}
                  onChange={this.handleBackupToCloudChange}
                  id="backupToCloud"
                />
                <span className={styles.checkmark}/>
              </label>
            </div>
          </div>
          <div>
            <label htmlFor="filesListProject" className={styles.top}>
              Project:
            </label>
            <div className={styles.inlineBlock}>
              {filesProjectItems.length > 0 ? (
                <ul id="filesListProject">{filesProjectItems}</ul>
              ) : (
                ''
              )}
            </div>
          </div>
          <div>
            <label htmlFor="filesListAudio" className={styles.top}>
              Audio:
            </label>
            <div className={styles.inlineBlock}>
              {filesAudioItems.length > 0 ? (
                <ul id="filesListAudio">{filesAudioItems}</ul>
              ) : (
                ''
              )}
            </div>
          </div>
          <div>
            <label htmlFor="filesListVideo" className={styles.top}>
              Video:
            </label>
            <div className={styles.inlineBlock}>
              {filesVideoItems.length > 0 ? (
                <ul id="filesListVideo">{filesVideoItems}</ul>
              ) : (
                ''
              )}
            </div>
          </div>
          <div style={{width: '100%', padding: '0.3em 0 0.7em 0'}}>
            {/*className={styles.top}*/}
            <label style={{width: '40%', textAlign: 'right'}} htmlFor="projectDescription">Project Description: </label>
            <textarea
              style={{width: '60%'}}
              id="projectDescription"
              name="projectDescription"
              onChange={this.descriptionOnChange}
              value={project.description}
            />
          </div>
          <Link to={ROUTE.projects.root}>Cancel</Link>
          <input
            type="button"
            value="Done"
            onClick={this.handleSubmitProject}
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps({projects, user}) {
  return {
    projects: projects,
    userId: user._id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(projectActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
