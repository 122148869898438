// import { bindActionCreators } from 'redux';
// import * as projectActions from '../../modules/projects/actions';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ROUTE} from "../../constants/general";
import WindowContainer from "../../components/WindowContainer/WindowContainer";
import {Field, Form, Formik} from "formik";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {object, string} from "yup";
import FormButtons from "../../components/FormButtons/FormButtons";
import PrivateContainer from "../../components/PrivateContainer";
import * as episodesActions from "../../modules/episodes/actions";

const initialState = {
  episode: {
    title: 'e01',
    description: 'descr 1\ndescr 2',
    keywords: 'key words'
  }
};

class EpisodeCreate extends Component {

  state = initialState;

  render() {
    const {seriesId, seasonId, save, fetchEpisodes, history} = this.props;
    const {episode} = this.state;

    return (
      <PrivateContainer>
        <WindowContainer history={this.props.history}>
          <h1>New Episode</h1>

          <Formik
            initialValues={episode}
            validationSchema={object().shape({
              title: string().required().min(1).max(250),
              description: string(),
              keywords: string()
            })}
            onSubmit={(values, {setSubmitting}) => {
              console.log('ep values', values);
              save(seriesId, seasonId, values).then(() => {
                setSubmitting(false);
                fetchEpisodes(seasonId);
                history.goBack();
              });
            }}
            render={({isSubmitting}) => {
              return (
                <Form>
                  <Field name="title" component={Input} label="Name" required/>
                  <Field name="description" type="textarea" component={Input} label="Description"/>
                  <Field name="keywords" component={Input} label="Keywords"/>
                  <FormButtons>
                    <Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY} icon="check">
                      Create
                    </Button>
                  </FormButtons>
                </Form>
              )
            }}
          />
        </WindowContainer>
      </PrivateContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  seriesId: ownProps.match.params.seriesId,
  seasonId: ownProps.match.params.seasonId
});

const mapDispatchToProps = dispatch => ({
  save: (seriesId, seasonId, values) => dispatch(episodesActions.insert({...values, seriesId, seasonId})),
  fetchEpisodes: seasonId => dispatch(episodesActions.fetchEpisodes(seasonId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeCreate);
