import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as userActions from '../../modules/user/actions';
import {ROUTE} from "../../constants/general";
import {Field, Form, Formik} from "formik";
import * as yup from 'yup';
import {Col, Row} from "react-flexbox-grid";
import Input from "../../components/Input/Input";
import FormButtons from "../../components/FormButtons/FormButtons";
import Button from "../../components/Button/Button";
import {Link} from "react-router-dom";


class Login extends Component {
  state = {page: 0};

  handleSubmit = async (values, {setSubmitting}) => {
    const {onRegister} = this.props;
    const {page} = this.state;

    const result = await onRegister(values);

    if (result === '') {
      this.setState({page: page + 1});
      //   history.push(ROUTE.home);
    } else {
      alert(result);
    }

    //process form submission here
    //done submitting, set submitting to false
    setSubmitting(false);
  };

  render() {
    const {page} = this.state;

    return (
      <Formik
        initialValues={{
          name: '',
          login: '',
          password: '',
          passwordConfirm: '',
          email: ''
          // name: 'test 002',
          // login: 'test 002',
          // password: '122',
          // passwordConfirm: '122',
          // email: 'stas.areshin+2@agnizar.com'
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required().min(1).max(100),
          login: yup.string().required().min(1).max(40),
          password: yup.string().required().min(1),
          passwordConfirm: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match'),
          email: yup.string().required().email()
        })}
        //enableReinitialize={true}
        onSubmit={this.handleSubmit}
        render={({isSubmitting}) => {
          return (
            <Form>
              <Row center="sm">
                <Col sm={6}>
                  {page === 1
                    ? <div>
                      Check your email please. After you confirm your email - you will be able to
                      {' '}<Link to={ROUTE.login}>login</Link>
                    </div>
                    : <Fragment>
                      <Field name="name" component={Input} label="Name" required/>
                      <Field name="login" component={Input} label="Login" required/>
                      <Field name="password" type="password" component={Input} label="Password" required/>
                      <Field name="passwordConfirm" type="password" component={Input} label="Confirm password"
                             required/>
                      <Field name="email" type="email" component={Input} label="Email" required/>
                    </Fragment>
                  }
                </Col>
              </Row>
              {page === 1
                ? ''
                : <FormButtons>
                  <Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY} icon="check">
                    Register
                  </Button>
                </FormButtons>
              }
            </Form>
          );
        }}
      />);
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onRegister: user => dispatch(userActions.onRegister(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
