import React from 'react';
import {Switch, Route} from "react-router-dom";
import {ROUTE} from "../../constants/general";
import LoggedApp from "./LoggedApp";
import {connect} from "react-redux";
import LoginRequiredRoute from "../../components/LoginRequiredRoute";
import Login from "../login/Login";
import Register from "../login/Register";

class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={ROUTE.register} component={Register}/>
        <Route path={ROUTE.login} component={Login}/>
        <LoginRequiredRoute component={LoggedApp}/>
      </Switch>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(App);
