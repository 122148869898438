const ALGORITHM = {
  sha256: 'SHA-256',
  sha384: 'SHA-384',
  sha512: 'SHA-512'
};

export const sha256 = message =>
  shaDigest(message, ALGORITHM.sha256)
    .then(hashBuffer => hexString(hashBuffer))
    .catch(err => {
      console.log(err.message); // TODO: Handle error
    });

export const sha512 = message =>
  shaDigest(message, ALGORITHM.sha512)
    .then(hashBuffer => hexString(hashBuffer))
    .catch(err => {
      console.log(err.message); // TODO: Handle error
    });

const shaDigest = (message: string, algorithm) =>
  crypto.subtle.digest(algorithm, new TextEncoder('utf-8').encode(message));

// convert bytes to hex string
const hexString = buffer =>
  [...new Uint8Array(buffer)]
    .map((value: string) => value.toString(16).padStart(2, '0'))
    .join('');
