import * as qs from "qs/lib";

const api = {
  authData: null,
  getItem(key) {
    if (!this.authData) {
      try {
        this.authData = JSON.parse(localStorage.getItem('authData'));
      } catch (e) {
        this.authData = {};
      }
    }
    return this.authData[key];
  },
  getToken() {
    this.getItem('token')
  },
  getRefreshToken() {
    this.getItem('refresh-token')
  }
};

const processResult = (response) => {
  if (!response || !response.ok) {
    throw response;
  }
  // console.info('response', response);
  switch (response.status) {
    case 200:
    case 201:
      return processResponse(response);
    default:
      throw response;
  }
};
const processResponse = (response) => {
  return (response.headers.get('Content-Type').indexOf('text/plain') === -1)
    ? response.json()
    : response.text();
};

export default class Request {
  requestUrl = null;
  queryParams = {};
  _headers = {};
  _token = null;

  constructor(method, url, data) {
    this.buildRequestUrl(method, url, data);
    this.params = {
      method,
      body: data && method !== 'get' ? JSON.stringify(this.queryParams) : null,
      cache: 'no-cache',
      mode: 'cors',
      timeout: 60000,
    };
  }

  setHeader(name, value) {
    this._headers[name] = value;
  }

  get headers() {
    return this._headers;
  }

  set token(value) {
    this._token = value;
  }

  get token() {
    return this._token;
  }

  buildRequestUrl(method, url, data) {
    this.requestUrl = url;

    // Build the url. In case of get request data should be in url string.
    if (data) {
      // Some data items can be used in url as placeholders in form %NAME%.
      // Those data items should be removed from query string.
      Object.entries(data).forEach(([key, value]) => {
        const paramName = `%${key}%`;
        if (this.requestUrl.indexOf(paramName) !== -1) {
          this.requestUrl = this.requestUrl.replace(paramName, value);
        } else {
          this.queryParams[key] = value;
        }
      });

      if (method === 'get' && Object.keys(this.queryParams).length) {
        const separator = this.requestUrl.split('?')[1] ? '&' : '?';
        this.requestUrl = `${this.requestUrl}${separator}${qs.stringify(this.queryParams)}`;
      }
    }
  }

  buildRequestHeaders() {
    if (this.token) {
      this.setHeader('Authorization', `Bearer ${this.token}`);
    }
    // if (apiToken.getUser()) {
    // this.setHeader('x-pm-user', apiToken.getUser());
    // this.setHeader('x-pm-usertype', apiToken.getUserType());
    // this.setHeader('x-pm-timestamp', apiToken.getTimestamp());
    // this.setHeader('x-pm-token', apiToken.getToken());
    // }

    return new Headers(this.headers);
  }

  execute() {
    this.params.headers = this.buildRequestHeaders();

    // console.info('this.requestUrl', this.requestUrl);
    // console.info('this.params', this.params);
    // console.info('this.params.headers.get(\'Authorization\')', this.params.headers.get('Authorization'));

    return fetch(this.requestUrl, this.params)
      .then(response => processResult(response))
      .catch(e => {
        console.error('Request error', e);
        if (e.status === 401) {
          // window.parent.postMessage('BF.AUTHENTICATION_FAILED', '*');
        }
        if (e.status === 400) {
          return processResponse(e);
        }
        return e;
      });
  }

  // async execute() {
  //   this.params.headers = this.buildRequestHeaders();
  //   try {
  //     const response = await fetch(this.requestUrl, this.params);
  //     const responseResult = await processResult(response);
  //     return responseResult;
  //   } catch (e) {
  //     if (e.status === 401) {
  //       window.parent.postMessage('BF.AUTHENTICATION_FAILED', '*');
  //     }
  //     return e;
  //   }
  // }
}
