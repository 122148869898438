// import cn from 'classnames';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as episodesActions from "../../modules/episodes/actions";
import styles from './Episode.module.css';
import {Link, Route} from 'react-router-dom';
import {ROUTE} from "../../constants/general";
import Button from "../../components/Button/Button";
import LinkListDetailRoute from "../links/LinkListDetailRoute";
import {DOC_TYPES} from "../../utils/pouchDB/documents";

const initialState = {
  episode: {}
};

class Episode extends Component {
  state = initialState;

  componentDidMount() {
    this.updateData();
  }

  updateData = () => {
    const {episodeId, fetch} = this.props;
    fetch(episodeId).then(doc => {
      this.setState({episode: doc})
    });
  };


  composeFiled = (name, value) => {
    return (
      <div className={styles.fieldsRow}>
        <div className={styles.fieldName}>
          {name}:
        </div>
        <div className={styles.fieldValue}>
          {value}
        </div>
      </div>
    );
  };

  render() {
    const {seasonIdFilter, episodeId, seasonId, history} = this.props;
    let {episode, showVideos} = this.state;

    console.info('episode ' + seasonId + ' this.props', this.props);
    if (episode === undefined) {
      console.error('season undef. why?', episode, seasonId);
      episode = {};
    }

    // console.error('seriesId, seasonId, link, this', seriesId, seasonId, ROUTE.series.seasons.episodes.root(seriesId, seasonId), this.props);
    const actions = [
        <Button key="showVideos" onClick={() => {
          history.push(ROUTE.series.seasons.episodes.links.root(episode.seriesId, episode.seasonId, episodeId, DOC_TYPES.file.media));
        }}>
          show Videos
        </Button>
    ];

    return (
      <Fragment>
        <h1>{episode.title}</h1>

        <div className={styles.seasonData}>
          <img className={styles.image} src="// TODO" alt={episode.title}/>
          <div className={styles.fieldsBlock}>
            {this.composeFiled('Ep ID', episode._id)}
            {this.composeFiled('Created', new Date(episode.created).toLocaleDateString())}
            {this.composeFiled('Description', episode.description)}
          </div>
        </div>
        {actions}
        <Route path={ROUTE.series.seasons.episodes.links.root(episode.seriesId, episode.seasonId, episode._id, DOC_TYPES.file.media)} component={LinkListDetailRoute}/>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // seriesId: ownProps.match.params.seriesId,
  // seriesId: state.match.params.seriesId,
  // seasonId: ownProps.match.params.seasonId
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(episodesActions.fetch(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Episode);
