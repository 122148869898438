// import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';
import store, {history} from './store'
import App from './containers/app/App'
import {initServiceDB} from './modules/service/actions';

import 'sanitize.css/sanitize.css'
import './index.css'

const target = document.querySelector('#root');

const renderApp = () => {
  render(
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>,
    target
  )
// render(
//   <Provider store={store}>
//     <ConnectedRouter history={history}>
//       <div>
//         <App />
//       </div>
//     </ConnectedRouter>
//   </Provider>,
//   target
// )
};

const getAppClient = async () => {
  // React.createContext();
  //
  await store.dispatch(initServiceDB());

  renderApp();
};

getAppClient();
