import React, {Component} from 'react';
import * as Yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from 'formik';

import {connect} from "react-redux";
import {Col, Row} from "react-flexbox-grid";
import styles from "./user.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";

const initialState = {
  user: {}
};

class Account extends Component {
  state = initialState;

  componentDidMount() {
    this.schema = Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      login: Yup.string()
        .required("Required"),
      phone: Yup.string()
        .required("Required")
    });
  }

  render() {
    const {user} = this.props;
    return (
      <div className={styles.user}>
        <Formik
          initialValues={user}
          validationSchema={this.schema}
          onSubmit={values => {
            console.log('values:', values);
          }}
          render={({values, errors, touched}) => (
            <Form>
              <Row>
                <h1>{values.login}</h1>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="email">Email</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field name="email" placeholder="example@domain.com" type="email"/>
                  <ErrorMessage name="email"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="created">Created</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{values.created}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="phone">Phone</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field name="phone" placeholder="555-555-5555"/>
                  <ErrorMessage name="phone"/>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <Row>
                    <Col>
                      <label htmlFor="cardNumber">Card</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field name="cardNumber" placeholder="5555-5555-55555"/>
                      <ErrorMessage name="cardNumber"/>
                    </Col>
                  </Row>
                </Col>
                <Col xs={2}>
                  <Row>
                    <Col>
                      <label htmlFor="cardExpired">Exp</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field name="cardExpired" placeholder="55/55"/>
                      <ErrorMessage name="cardExpired"/>
                    </Col>
                  </Row>
                </Col>
                <Col xs={2}>
                  <Row>
                    <Col>
                      <label htmlFor="cardCsv">CSV</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field name="cardCsv" placeholder="555"/>
                      <ErrorMessage name="cardCsv"/>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Tabs>
                <TabList>
                  <Tab>Billing History</Tab>
                  <Tab>Settings</Tab>
                </TabList>

                <TabPanel>
                  <h2>Any content 1</h2>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 2</h2>
                </TabPanel>
              </Tabs>
              <Row>
                <button type="submit">Save</button>
              </Row>
            </Form>
          )}
        />

        {/*<h1>{user.name}</h1>*/}
        {/*<p>ID: {user._id}</p>*/}
        {/*<p>Created</p>*/}
        {/*<p>Phone</p>*/}
        {/*<p>Card</p>*/}
        {/*<br/>*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

// const mapDispatchToProps = dispatch =>
//     bindActionCreators(userActions, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(Account);
