import React from 'react';
import PropTypes from 'prop-types';

import styles from './Input.module.css';

const RadioButtons = props => (
    <div className={props.className}>
        {
            props.options.map((option, index) => {
                const id = `${props.id}_${index}`;
                return (
                    <label
                        htmlFor={id}
                        key={option.value}
                        className={styles.radioButton}
                    >
                        <input
                            id={id}
                            name={props.name}
                            type="radio"
                            value={option.value}
                            checked={props.value === option.value}
                            onBlur={props.onBlur}
                            onChange={props.onChange}
                        />
                        {option.label}
                    </label>
                );
            })
        }
    </div>
);

RadioButtons.propTypes = {
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
};

export default RadioButtons;
